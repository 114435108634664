import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, concatMap, map} from "rxjs/operators";
import {ValioVendorConnector} from "./valio-vendor.connector";
import {LOAD_VENDORS, LoadVendorsFail, LoadVendorsSuccess, VendorsAction} from "./valio-vendor.action";
import {makeErrorSerializable} from "../util/valio-serialization-utils";
import {UserIdService} from "@spartacus/core";

@Injectable()
export class VendorEffects {

  private loadVendors$: Observable<LoadVendorsSuccess | LoadVendorsFail>;

  constructor(
        private actions$: Actions,
        private vendorConnector: ValioVendorConnector,
        private userIdService: UserIdService
    ) {
      this.loadVendors$ = createEffect(() => this.actions$.pipe(
          ofType(LOAD_VENDORS),
          concatMap(() => {
            return this.userIdService.getUserId().pipe(
              concatMap(userId => this.vendorConnector.getVendors(userId).pipe(
                map(vendors => {
                  return new LoadVendorsSuccess(vendors);
                }),
                catchError(error =>
                  of(new LoadVendorsFail(makeErrorSerializable(error)))
                )
              ))
            );
          })
        )
      );
    }



}
