import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {ConfigModule, StateConfig, StateTransferType} from "@spartacus/core";
import {ANALYTICS_FEATURE} from "./valio-environment-state";
import {ValioEnvironmentEffects} from "./valio-environment.effect";
import {metaReducers, reducer} from "./valio-environment.reducer";


export function environmentStoreConfigFactory(): StateConfig {
  return {
    state: {
      ssrTransfer: {
        keys: {
          analyticsConfig: StateTransferType.TRANSFER_STATE
        }
      }
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ANALYTICS_FEATURE, reducer, {metaReducers}),
    EffectsModule.forFeature([ValioEnvironmentEffects]),
// TODO:Spartacus - The 'withConfigFactory' method's signature changed to: 'withConfigFactory(  configFactory: ConfigFactory,  deps: any[]): ModuleWithProviders<ConfigModule>'
    ConfigModule.withConfigFactory(environmentStoreConfigFactory),
  ]
})
export class ValioEnvironmentModule {
}

