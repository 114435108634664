<ng-container *ngIf="product.purchasable; else showMore">
  <ng-container *ngIf="!isShowAddToCartAndQuantity(); else addToCartProductDetails">
    <div *ngIf="getProductCode() && isShowQuantity()" class="quantity">
      <valio-cx-item-counter
        (update)="updateAmount($event)"
        *ngIf="isHasStock()"
        [id]="'itemCounterInput_'+product.code"
        [max]="maxQuantity"
        [min]="1"
        [showButtons]="true"
        [unit]="product.unit"
        [value]="quantity"
        [styleClass]="'cx-counter cx-counter-product-card'"
        isValueChangeable="true"
      ></valio-cx-item-counter>
    </div>
    <span
      *ngIf="isShowAddToCart()"
      [title]="!isHasStock()?('icons.cartDisabled'|cxTranslate):''">
      <button
        (click)="addToCart()"
        [disabled]="!isHasStock()"
        class="btn btn-primary btn-cart btn-square btn-icon"
        type="button"
      >
        <ng-container *ngIf="quantity > 0 ; else cartIcon">
          <div class="btn-text-quantity">{{ quantity }}</div>
          <div class="btn-text-unit">{{ product.unit?.name }}</div>
        </ng-container>
        <ng-template #cartIcon>
          <span class="valio-icon icon-cart-inv"></span>
        </ng-template>
      </button>
    </span>
  </ng-container>

  <ng-template #addToCartProductDetails>
    <div class="quantity">
      <valio-cx-item-counter
        (update)="updateQuantity($event)"
        [stepperTimeout]="0"
        *ngIf="isHasStock()"
        [id]="'itemCounterInput_'+product.code"
        [max]="maxQuantity"
        [min]="1"
        [showButtons]="true"
        [unit]="product.unit"
        [value]="quantity"
        [styleClass]="'cx-counter cx-counter-lg cx-counter-product-detail'"
        isValueChangeable="true"
      ></valio-cx-item-counter>
    </div>
    <button
      (click)="addToCart()"
      *ngIf="isHasStock()"
      class="btn btn-lg btn-primary btn-product-cart margin-left-sm"
      type="button"
    >
      {{ 'valioProduct.productDetails.' + itemAddedToCartLabel |cxTranslate }}
    </button>
    <div *ngIf="!isHasStock()">
      {{ 'valioProduct.productDetails.notAvailable'|cxTranslate }}
    </div>
  </ng-template>
</ng-container>

<ng-template #showMore>
  <ng-container *ngIf="!product.closingTimePassed; else closingTimePassed">
    <ng-container *ngIf="!isShowAddToCartAndQuantity()">
      <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
         class="btn btn-sm btn-primary btn-block">{{ 'valioProduct.showMore'|cxTranslate }}</a>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #closingTimePassed>
  <button
    (click)="addToDate(product,product.nextDeliveryDate)"
    *ngIf="isShowAddToCart()"
    [disabled]="!isHasStock()"
    class="btn btn-primary btn-cart btn-square btn-icon"
    title="{{product.nextDeliveryDate?('valioProduct.closingTimePassedProduct'|cxTranslate: {date: convertDate(product.nextDeliveryDate)|valioDate:'d.M.'}):'' }}"
    type="button"
  >
    <ng-container *ngIf="quantity > 0">
      <div class="btn-text-quantity">{{ quantity }}</div>
      <div class="btn-text-unit">{{ product.unit?.name }}</div>
    </ng-container>
    <ng-container *ngIf="quantity == 0 && product.nextDeliveryDate">
      <span
        *ngIf="isShowAddToCartAndQuantity()"> {{ 'valioProduct.productDetails.addToCart'|cxTranslate }}</span> {{ convertDate(product.nextDeliveryDate)|valioDate:'d.M.' }}
    </ng-container>
    <span *ngIf="!isShowAddToCartAndQuantity() && quantity == 0 && !product.nextDeliveryDate" class="valio-icon icon-cart-inv"></span>
  </button>
</ng-template>
