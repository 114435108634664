import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {
  
  AuthService,
  CmsService,
  PageType,
  ProtectedRoutesService,
  RoutingService,
  SemanticPathService,
} from '@spartacus/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LogoutGuard} from "@spartacus/storefront";

@Injectable({
  providedIn: 'root',
})
export class ValioLogoutGuard extends LogoutGuard {

  constructor(
    protected auth: AuthService,
    protected cms: CmsService,
    protected semanticPathService: SemanticPathService,
    protected protectedRoutes: ProtectedRoutesService,
    protected router: Router
    ,
  ) {
    super(      auth, cms, semanticPathService, protectedRoutes, router);
  }
/*
  canActivate(): Observable<any> {
    this.logout().then(value => {
        console.info("Logout done");
      }
    )
    return this.cms.hasPage({
        id: this.semanticPathService.get('logout'),
        type: PageType.CONTENT_PAGE,
      })
      .pipe(
        tap(hasPage => {
          if (!hasPage) {
            this.redirect();
          }
        })
      );
  }

  protected redirect(): void {
    this.routing.go('/');
  }

  protected logout(): void {
    this.auth.logout();
  }
  */
}
