import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AuthRedirectService,
  AuthService,
  FeatureConfigService,
  GlobalMessageEntities,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
} from "@spartacus/core";
import {BehaviorSubject, Subscription} from 'rxjs';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {filter} from "rxjs/operators";
import {ValioUser, ValioUserSignUp} from "../../../../models/misc.model";
import {CustomFormValidators} from "@spartacus/storefront";
import VatIdValidator from "../../../../shared/utils/validators/VatIdValidator";
import {PostalCodeService} from "../../../../services/user/postalCode.service";
import {UserIdValidator} from "../../../../shared/utils/validators/UserIdValidator";
import {ValioRegisterExistingComponent} from "./valio-register-existing.component";
import {ValioGoogleAnalyticsService} from "../../../../services/analytics/valio-google-analytics.service";
import PostalCodeValidator from "../../../../shared/utils/validators/PostalCodeValidator";
import Atleast1LetterValidator from "../../../../shared/utils/validators/Atleast1LetterValidator";
import {RegisterComponentService} from "@spartacus/user/profile/components";
import {ValioCustomFormValidators} from "../../../../shared/validators/valio-custom-form-validators";


@Component({
  selector: 'valio-cx-register-new',
  templateUrl: './valio-register-new.component.html',
  providers: [ValioGoogleAnalyticsService]
})
export class ValioRegisterNewComponent implements OnInit, OnDestroy {

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private subscription: Subscription = new Subscription();
  showStep1: boolean = true;
  showThanksInvalidZipCode: boolean = false;
  userRegistrationFormStep1: UntypedFormGroup;
  isNewRegisterFlowEnabled: boolean = false;

  /**
   * @deprecated since 1.1.0
   */
  constructor(
    protected auth: AuthService,
    protected authRedirectService: AuthRedirectService,
    protected registerComponentService: RegisterComponentService,
    protected globalMessageService: GlobalMessageService,
    protected fb: UntypedFormBuilder,
    protected postalCodeService: PostalCodeService,
    protected userIdValidator: UserIdValidator,
    protected googleAnalyticsService: ValioGoogleAnalyticsService,
    protected router?: RoutingService,
    protected featureConfig?: FeatureConfigService
  ) {
  }


  ngOnInit() {
    this.isNewRegisterFlowEnabled = this.featureConfig && this.featureConfig.isLevel('1.1');

    this.userRegistrationFormStep1 = this.fb.group(
      {
        vatId: ['', [Validators.required, VatIdValidator.valid]],
        city: ['', Atleast1LetterValidator.valid],
        postalCode: ['', [Validators.required, PostalCodeValidator.valid]],
        companyName: ['', Atleast1LetterValidator.valid],
        companyNameDepartment: ['', Atleast1LetterValidator.valid],
        firstName: ['', Atleast1LetterValidator.valid],
        lastName: ['', Atleast1LetterValidator.valid],
        email: ['', [Validators.required, CustomFormValidators.emailValidator]],
        emailconf: ['', Validators.required],
        termsandconditions: [false, Validators.requiredTrue],
        street: ['', Atleast1LetterValidator.valid],
        phoneNumber: ['+358', ValioRegisterExistingComponent.phoneNumberValidatorForCms],
        userId: new UntypedFormControl('', {
          validators: Validators.required,
          asyncValidators: this.userIdValidator.validate.bind(this.userIdValidator),
          updateOn: 'blur'
        }),
        password: [
          '',
          [Validators.required,
            ValioCustomFormValidators.passwordValidator],
        ],
        passwordconf: ['',
          Validators.required,

          /*this.matchPassword*/],
      },
      {
        validators: [this.matchEmail,
          CustomFormValidators.passwordsMustMatch('password', 'passwordconf')
        ]
      }
    );
    // TODO(issue:4237) Register flow
    if (this.isNewRegisterFlowEnabled) {
      this.isLoading$.next(false);
    } else {
      if (this.auth && this.authRedirectService) {
        this.subscription.add(
          this.auth.isUserLoggedIn().subscribe(isLogged => {
            if (isLogged) {
              this.globalMessageService.remove(
                GlobalMessageType.MSG_TYPE_ERROR
              );
              this.authRedirectService.redirect();
            }
          })
        );
      }
    }

    // TODO: Workaround: allow server for decide is titleCode mandatory (if yes, provide personalized message)
    this.subscription.add(
      this.globalMessageService
        .get()
        .pipe(filter(messages => !!Object.keys(messages).length))
        .subscribe((globalMessageEntities: GlobalMessageEntities) => {
          const messages =
            globalMessageEntities &&
            globalMessageEntities[GlobalMessageType.MSG_TYPE_ERROR];

          if (
            messages &&
            messages.some(message => message.raw === 'This field is required.')
          ) {
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.add(
              {key: 'register.titleRequired'},
              GlobalMessageType.MSG_TYPE_ERROR
            );
          }
        })
    );
  }

  submit(): void {
    if (!this.userRegistrationFormStep1.valid) {
      this.submittedStep1 = true;
      return;
    }
    this.isLoading$.next(true);
    this.registerComponentService
      .register(this.collectDataFromRegisterForm(this.userRegistrationFormStep1.value))
      .subscribe({
        next: (user: ValioUser) => this.doOnRegisterUserSuccess(user),
        complete: () => this.onComplete(),
        error: (error) => this.onError(error)
      });
  }

  private onComplete() {
    this.isLoading$.next(false)
  }

  private onError(error: any): void {
    if (error) {
      console.error(JSON.stringify(error));
    }
    this.isLoading$.next(false);
  }

  private doOnRegisterUserSuccess(user: ValioUser): void {
    this.onRegisterUserSuccess(true)
    this.googleAnalyticsService.createRegisterEvent(false);
  }

  collectDataFromRegisterForm(formDataStep1: any): ValioUserSignUp {
    const {
      postalCode,
      companyName,
      vatId,
      city,
      companyNameDepartment,
      firstName,
      lastName,
      email,
      userId,
      phoneNumber,
      password,
      street
    } = formDataStep1;
    return {
      firstName,
      lastName,
      email,
      password,
      uid: userId,
      phoneNumber,
      street,
      postalCode,
      companyName,
      companyNameDepartment,
      vatId,
      city
    };
  }

  private onRegisterUserSuccess(success: boolean): void {
    if (success) {
      this.googleAnalyticsService.createRegisterEvent(false);
      this.showThanksForInvalidZipCode();
    }
  }

  private matchEmail(ac: AbstractControl): { NotEqual: boolean } {
    if (ac.get('email').value !== ac.get('emailconf').value) {
      return {NotEqual: true};
    }
    return null;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private submittedStep1 = false;

  goToFirstStep() {
    this.showStep1 = true;
    this.showThanksInvalidZipCode = false;
  }

  private showThanksForInvalidZipCode() {
    this.resetSubmitted();
    this.showStep1 = false;
    this.showThanksInvalidZipCode = true;
  }

  goToLoginPage() {
    this.router.go('login');
  }

  goToHomePage() {
    this.router.go('/');
  }

  isInvalidStep1(fieldName: string): boolean {
    return this.submittedStep1 && this.userRegistrationFormStep1.get(fieldName).invalid;
  }

  private resetSubmitted() {
    this.submittedStep1 = false;
  }

  validateForm() {
    this.userRegistrationFormStep1.controls['userId'].updateValueAndValidity();
  }
}
