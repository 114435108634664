import {ChangeDetectionStrategy, Component, OnDestroy, OnInit,} from '@angular/core';
import {Observable} from 'rxjs';
import {OrderFacade} from "@spartacus/order/root";

@Component({
  selector: 'cx-order-confirmation-overview',
  templateUrl: './order-confirmation-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderConfirmationOverviewComponent implements OnInit, OnDestroy {
  protected order$: Observable<any>;

  constructor(
    protected orderFacade: OrderFacade
  ) {
  }

  ngOnInit() {
    this.order$ = this.orderFacade.getOrderDetails();
  }

  ngOnDestroy() {
    this.orderFacade.clearPlacedOrder();
  }
}
