import {Inject, Injectable, Injector} from '@angular/core';
import {ContextServiceMap, SiteContextConfig, SiteContextParamsService} from "@spartacus/core";
import {ALL_PARTNERS, PARTNER_CONTEXT_ID} from "./valio-site.constants";
import {DOCUMENT} from "@angular/common";

@Injectable()
export class ValioSiteContextParamsService extends SiteContextParamsService {


  constructor(config: SiteContextConfig, injector: Injector, serviceMap: ContextServiceMap,
              @Inject(DOCUMENT) protected document: Document) {
    super(config, injector, serviceMap);
  }

  getParamValues(param: string): string[] {
    if (param == PARTNER_CONTEXT_ID) {
      const url = this.document.defaultView.window.location.href;
      return [url.split('/')[4], ALL_PARTNERS];// accept all
    }
    return super.getParamValues(param);
  }

  getParamDefaultValue(param: string): string | undefined {
    if (param == PARTNER_CONTEXT_ID) {
      return ALL_PARTNERS;
    }
    return super.getParamDefaultValue(param);
  }
}
