import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {ValioRouteCalendarService} from "../../../services/routecalendar/valio-routecalendar.service";
import {ValioUserAccountFacade} from "../../../services/user/valio-user-account.facade";
import {concatMap, map, withLatestFrom} from "rxjs/operators";
import {RoutingService} from "@spartacus/core";
import {ValioUserService} from "../../../services/user/valio-user.service";

@Injectable({
  providedIn: 'root',
})
export class ValioNavigationGuard  {

  constructor(
    protected routingService: RoutingService,
    protected userAccountFacade: ValioUserAccountFacade,
    protected routeCalendarService: ValioRouteCalendarService,
    protected userService: ValioUserService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const isLoaded$: Observable<boolean> = this.routeCalendarService.isLoaded();
    const isAnonymous$: Observable<boolean> = this.userService.isAnonymous();

    return isLoaded$.pipe(withLatestFrom(isAnonymous$))
      .pipe(concatMap((value: [boolean, boolean]) => {
        const isLoaded: boolean = value[0];
        const isAnonymous: boolean = value[1]
        if (isAnonymous) {
          return of(isLoaded);
        }
        return this.userAccountFacade.get().pipe(
          map(user => {
            // Check if user is required to change password and password skip is not allowed.
            // Check current url, otherwise browser will go to endless loop
            if (user?.requirePasswordChange && !user?.allowPasswordChangeSkip && state.url.indexOf('/my-account/force-update-password')<0 ) {
              this.routingService.go('/my-account/force-update-password', {queryParams: {force: !user.allowPasswordChangeSkip}});
              return false;
            }
            return isLoaded;
          }))
      }));
  }
}
