import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CartActions} from "@spartacus/cart/base/core";
import {
  GlobalMessageActions,
  GlobalMessageType,
  RoutingService,
  UserActions
} from "@spartacus/core";
import {from, Observable, of} from 'rxjs';
import {catchError, concatMap, map, mergeMap} from 'rxjs/operators';
import {ValioCartConnector} from "../cart/valio-cart.connector";
import {ValioCartService} from "../cart/valio-cart.service";
import {ValioDatePipe} from "../pipes/valio-date.pipe";
import {openCloseSpinner} from "../util/valio-modals-utils";
import {makeErrorSerializable} from "../util/valio-serialization-utils";
import {
  INVOICE_SUCCESS,
  LOAD_ORDER,
  PAYMENT_STARTED,
  START_INVOICE,
  START_PAYMENT,
  ValioInvoiceFailed,
  ValioInvoiceSuccess,
  ValioLoadOrder,
  ValioPaymentStarted, ValioRedirectStarted
} from "./valio-checkout.action";
import {ValioCheckoutConnector} from "./valio-checkout.connector";
import {CheckoutActions} from "../../features/checkout/core/store";
import {DOCUMENT} from "@angular/common";

@Injectable()
export class ValioCheckoutEffects {
  private startPayment$: Observable<| ValioPaymentStarted | UserActions.LoadUserPaymentMethodsFail>
  private forwardToPayment$: Observable<ValioRedirectStarted>
  private startInvoice$: Observable<| ValioInvoiceSuccess | ValioInvoiceFailed | CartActions.LoadCart | GlobalMessageActions.AddMessage>
  private forwardAfterInvoicePage$: Observable<| ValioLoadOrder | CartActions.ResetCartDetails | CartActions.LoadCartSuccess | ValioInvoiceFailed | GlobalMessageActions.AddMessage>
  private loadOrder$: Observable<| CheckoutActions.PlaceOrderSuccess | CheckoutActions.PlaceOrderFail | CartActions.LoadCartSuccess | CartActions.ResetCartDetails | GlobalMessageActions.AddMessage>

  constructor(
    protected lactions$: Actions,
    protected lcheckoutConnector: ValioCheckoutConnector,
    protected routingService: RoutingService,
    protected cartService: ValioCartService,
    protected cxDate: ValioDatePipe,
    protected cartConnector: ValioCartConnector,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.startPayment$ = createEffect(() => this.lactions$.pipe(
      ofType(START_PAYMENT),
      map((action: any) => action.payload),
      mergeMap(payload => {
          // get information for creating a subscription directly with payment provider
          openCloseSpinner(this.document, true);
          return this.lcheckoutConnector
            .payment(payload.userId, payload.cartId, payload.date)
            .pipe(
              concatMap(data => {
                  return [new ValioPaymentStarted(data)];
                }
              ),
              catchError(error =>
                of(new UserActions.LoadUserPaymentMethodsFail(makeErrorSerializable(error)))
              )
            );
        }
      ))
    );


    this.forwardToPayment$= createEffect(() => this.lactions$.pipe(
        ofType(PAYMENT_STARTED),
        map((action: ValioPaymentStarted) => action.link),
        mergeMap((link:string) => {
          window.location.href = link;
          return [new ValioRedirectStarted('')];
        })
      )
    );


    this.startInvoice$ = createEffect(() => this.lactions$.pipe(
        ofType(START_INVOICE),
        map((action: any) => action.payload),
        mergeMap(payload => {
            openCloseSpinner(this.document, true);
            return this.lcheckoutConnector
              .placeValioOrder(payload.userId, payload.cartId, payload.date)
              .pipe(
                concatMap(data => [new ValioInvoiceSuccess(data, payload.date, payload.userId, payload.cartId)])
              );
          }
        ),
        catchError(error => {
            openCloseSpinner(this.document, false);
            return from([
              new GlobalMessageActions.AddMessage({
                type: GlobalMessageType.MSG_TYPE_ERROR,
                text: {raw: error.error.errors[0].message},
                timeout: undefined,
              }),
              new ValioInvoiceFailed(makeErrorSerializable(error))
            ]);
          }
        )
      )
    );
    this.forwardAfterInvoicePage$ = createEffect(() => this.lactions$.pipe(
        ofType(INVOICE_SUCCESS),
        map((action: ValioInvoiceSuccess) => action),
        concatMap(action => {
          openCloseSpinner(this.document, true);
          return this.cartConnector.load(action.userId, action.cartId).pipe(
            concatMap(cart => {
              if (this.isNotEmpty(action.data)) {
                this.routingService.go(action.data);
                return [new CartActions.LoadCartSuccess({
                  userId: action.userId,
                  cartId: action.cartId,
                  cart: cart,
                  extraData: {active: true}
                })];
              }
              return [
                new CartActions.ResetCartDetails(),
                new CartActions.LoadCartSuccess({
                  userId: action.userId,
                  cartId: action.cartId,
                  cart: cart,
                  extraData: {active: true}
                }),
                new ValioLoadOrder(cart.user.uid, action.cartId, action.date),
              ];
            }));
        }),
        catchError(error => {
            openCloseSpinner(this.document, false);
            return from([
              new GlobalMessageActions.AddMessage({
                type: GlobalMessageType.MSG_TYPE_ERROR,
                text: {raw: error.error.errors[0].message},
                timeout: undefined,
              }),
              new ValioInvoiceFailed(makeErrorSerializable(error))
            ]);
          }
        )
      )
    );
    this.loadOrder$ = createEffect(() => this.lactions$.pipe(
        ofType(LOAD_ORDER),
        map((action: ValioLoadOrder) => action),
        mergeMap(payload => {
            return this.lcheckoutConnector
              .loadOrder(payload.user, payload.date, payload.orderCode)
              .pipe(
                concatMap(order =>
                  [
                    new CheckoutActions.PlaceOrderSuccess(order)
                  ]
                ),
                catchError(error => {
                    openCloseSpinner(this.document, false);
                    return from([
                      new GlobalMessageActions.AddMessage({
                        type: GlobalMessageType.MSG_TYPE_ERROR,
                        text: {raw: error.error.errors[0].message},
                        timeout: undefined,
                      }),
                      new CheckoutActions.PlaceOrderFail(makeErrorSerializable(error))
                    ]);
                  }
                )
              );
          }
        )
      )
    );
  }



  isNotEmpty(value: any): boolean {
    if (typeof value === 'string') {
      return value.trim() !== '';
    }
    return value !== null && value !== undefined;
  }


}
