import {ActionReducer, MetaReducer} from "@ngrx/store";
import {ValioEnvironmentState} from "./valio-environment-state";
import {LOAD_ANALYTICS_SUCCESS, ValioEnvironmentAction} from "./valio-environment.action";

export const initialState: ValioEnvironmentState = {
  analyticsConfig: null
};

export const metaReducers: MetaReducer[] = [clearState];

export function clearState(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}

export function reducer(
  state = initialState,
  action: ValioEnvironmentAction
): ValioEnvironmentState {
  switch (action.type) {
    case LOAD_ANALYTICS_SUCCESS: {
      return <ValioEnvironmentState>{
        analyticsConfig: {
          webextendMerchantId: action?.payload?.webextendMerchantId,
          webextendEnabled: action?.payload?.webextendEnabled,
          qualtricsCode: action?.payload?.qualtricsCode
        }
      };
    }
  }

  return state;
}
