import {ValioPipeModule} from '../services/pipes/valio-pipe.module';
import {ValioProductSummaryModule} from './cms-components/product/product-summary/valio-product-summary.module';
import {FacetModule, ProductListModule, SearchBoxModule} from '@spartacus/storefront';
import {ValioProductListModule} from './cms-components/product/valio-product-list.module';
import {ValioRegisterModule} from './cms-components/user/register/valio-register.module';
import {ValioLoginModule} from './cms-components/user/login/valio-login.module';
import {ValioAddToCartModule} from './cms-components/cart/add-to-cart/valio-add-to-cart.module';
import {ValioCartDetailsModule} from './cms-components/cart/cart-detail/valio-cart-details.module';
import {ValioCartSharedModule} from './cms-components/cart/cart-shared/valio-cart-shared.module';
import {ValioImportFromFileModule} from "./cms-components/cart/file-import/valio-import-from-file.module";

import {ValioOrderConfirmationModule} from './cms-components/order-confirmation/valio-order-confirmation.module';
import {ValioCalendarModule} from './calendar/valio-calendar.module';
import {ValioCheckoutStoreModule} from '../services/checkout/valio-checkout.module';
import {ValioCheckoutModule} from './cms-components/checkout/valio-checkout.module';
import {ValioCartHeaderModule} from './cms-components/cart/cart-header/valio-cart-header.module';

import {ValioCheckoutOrchestratorModule} from './cms-components/checkout/checkout-orchestrator/valio-checkout-orchestrator.module';
import {ValioReviewSubmitModule} from './cms-components/checkout/review-submit/valio-review-submit.module';
import {ValioProductModule} from '../services/product/valio-product-module';
import {ValioReviewSubmitCreditModule} from './cms-components/checkout/review-submit/credit/valio-review-submit-credit.module';
import {ValioReviewSubmitInvoiceModule} from './cms-components/checkout/review-submit/invoice/valio-review-submit-invoice.module';
import {ValioMainModule} from './layout/main/valio-main.module';
import {ValioNavigationModule} from './cms-components/navigation/valio-navigation.module';
import {ValioLoginFormModule} from './cms-components/user/login-form/valio-login-form.module';
import {ValioBreadcrumbModule} from './cms-components/navigation/breadcrumb/valio-breadcrumb.module';
import {ValioFooterModule} from './cms-components/footer/valio-footer.module';
import {ValioSuggestiveSearchBoxModule} from './cms-components/navigation/suggestive-search-box/valio-suggestive-search-box.module';
import {ValioMainNavigationModule} from './cms-components/navigation/valio-main-navigation/valio-main-navigation.module';
import {ValioMobileNavigationModule} from './cms-components/navigation/valio-mobile-navigation/valio-mobile-navigation.module';
import {ValioUserStoreModule} from '../services/user/valio-user-store.module';
import {ValioProductNutritionModule} from './cms-components/product/product-nutrition/valio-product-nutrition.module';
import {ValioBackgroundImageModule} from './cms-components/content/background-image/valio-background-image.module';
import {ValioMyAccountModule} from './cms-components/myaccount/valio-my-account.module';
import {ValioFrontpageModule} from './cms-components/frontpage/valio-frontpage.module';
import {ValioFooterLogosModule} from './cms-components/footer/valio-footer-logos/valio-footer-logos.module';
import {ValioProductIconsModule} from './cms-components/product/product-icons/valio-product-icons.module';
import {ValioOrderHistoryModule} from './cms-components/order-history/valio-order-history.module';
import {ValioPublicProductNavigationModule} from './cms-components/navigation/valio-public-product-navigation/valio-public-product-navigation.module';
import {ValioDeliveryNoteModule} from './cms-components/deliverynotes/valio-deliverynote.module';
import {ValioCarouselModule} from './shared/components/carousel/valio-carousel.module';
import {ValioProductCarouselModule} from './cms-components/product/carousel/product-carousel/valio-product-carousel.module';
import {ValioOrderTemplateModule} from "./cms-components/order-template/valio-order-template.module";
import {ValioSearchBoxModule} from "./cms-components/navigation/search-box/valio-search-box.module";
import {ValioProductTooltipModule} from './cms-components/product/product-tooltip/valio-product-tooltip.module';
import {ValioOrderTemplateContentModule} from "./cms-components/order-template/content/valio-order-template-content.module";
import {ValioBannerModule} from './cms-components/content/banner/valio-banner.module';
import {ValioThreeBoxBannerModule} from './cms-components/content/three-box-banner/valio-three-box-banner.module';
import {ValioNewsItemModule} from './cms-components/content/news-item/valio-news-item.module';
import {ValioHeaderNewsModule} from './cms-components/content/news/valio-header-news.module';
import {ValioLastPurchasesModule} from "./cms-components/product/carousel/last-purchases/valio-last-purchases.module";
import {ValioResetPasswordModule} from "./cms-components/myaccount/reset-password/valio-reset-password.module";
import {ValioInputModule} from "./input/valio-input.module";
import {ValioRouteCalendarModule} from "../services/routecalendar/valio-routecalendar.module";
import {ValioLastPurchaseProductsModule} from "../services/lastPurchases/valio-last-purchases.module";
import {ValioCategoriesModule} from "../services/categories/valio-categories.module";
import {ValioLogoutModalModule} from "./shared/components/logout/valio-logout-modal.module";
import {ValioMiniCartModule} from './cms-components/cart/valio-mini-cart/valio-mini-cart.module';
import {ValioOrderTemplatesModule} from "../services/orderTemplates/valio-order-templates-module";
import {ValioFaqModule} from "./cms-components/content/faqs/valio-faq.module";
import {ValioPartnerCardModule} from "./cms-components/content/partner-card/valio-partner-card.module";
import {ValioPartnerApplyModule} from "./cms-components/content/partner-apply/valio-partner-apply.module";
import {ValioSiteContextSelectorModule} from './cms-components/site-context-selector/valio-site-context-selector.module';
import {ValioPublicVendorNavigationModule} from './cms-components/navigation/valio-public-vendor-navigation/valio-public-vendor-navigation.module';
import {ValioHamburgerMenuModule} from './layout/hamburger-menu/valio-hamburger-menu.module';
import {
  Config,
  ProductModule,
  provideDefaultConfig,
} from "@spartacus/core";
import {valioLayoutConfig} from "./cms/layout/config/valio-layout-config";
import {ValioVoucherModule} from "./cms-components/checkout/voucher/valio-voucher.module";
import {ValioProductDeliveryPeriodModule} from "./cms-components/product/product-delivery-period/valio-product-delivery-period.module";
import {ValioEnvironmentModule} from "../services/environment/valio-environment.module";
import {ValioVideoModule} from "./cms-components/content/video/valio-video.module";
import {ValioTopSellersModule} from "./cms-components/product/carousel/top-sellers/valio-top-sellers.module";
import {ValioTopSellerProductsModule} from "../services/recommendations/top-sellers/valio-top-sellers.module";
import {ValioBoughtTogetherModule} from "./cms-components/product/carousel/bought-together/valio-bought-together.module";
import {ValioBoughtTogetherProductsModule} from "../services/recommendations/bought-together/valio-bought-together.module";
import {CmsParagraphModule} from "./cms-components/content/paragraph/paragraph.module";
import {UserAccountCoreModule} from "@spartacus/user/account/core";
import {UserAccountOccModule} from "@spartacus/user/account/occ";
import {CheckoutModule} from "./checkout/checkout.module";
import {SpartacusModule} from "../spartacus/spartacus.module";
import {SmartEditFeatureModule} from "../spartacus/features/smartedit/smart-edit-feature.module";
import {LoginFormModule, LoginModule} from "@spartacus/user/account/components";
import {UserAccountModule} from "@spartacus/user/account";
import {CartBaseModule} from "@spartacus/cart/base";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {ValioSiteContextModule} from "../services/site/valio-site-context.module";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    ValioSiteContextModule,
  ],
  providers: [
    provideDefaultConfig(valioLayoutConfig as Config),
  ],
  exports: [
    ValioLogoutModalModule,
    //Spartacus
    SpartacusModule,
    SmartEditFeatureModule,
    // AsmFeatureModule,
    LoginModule,
    LoginFormModule,
    ProductModule,
    UserAccountModule,
    CartBaseModule,
    FacetModule,
    SearchBoxModule,
    //VALO
    ValioMainModule,
    ValioProductModule,
    ValioProductSummaryModule,
    ProductListModule,
    ValioProductListModule,
    ValioRegisterModule,
    ValioLoginModule,
    ValioLoginFormModule,
    ValioAddToCartModule,
    ValioCartDetailsModule,
    ValioCartSharedModule,
    ValioImportFromFileModule,
    ValioOrderConfirmationModule,
    ValioCheckoutStoreModule,
    ValioCalendarModule,
    ValioCheckoutModule,
    ValioCartHeaderModule,
    ValioPipeModule,
    ValioCheckoutOrchestratorModule,
    ValioReviewSubmitModule,
    ValioReviewSubmitCreditModule,
    ValioReviewSubmitInvoiceModule,
    ValioMainModule,
    ValioNavigationModule,
    ValioBreadcrumbModule,
    ValioFooterModule,
    ValioSuggestiveSearchBoxModule,
    ValioMainNavigationModule,
    ValioPublicProductNavigationModule,
    ValioPublicVendorNavigationModule,
    ValioMobileNavigationModule,
    ValioUserStoreModule,
    ValioProductNutritionModule,
    ValioBackgroundImageModule,
    ValioMyAccountModule,
    ValioFrontpageModule,
    ValioLastPurchasesModule,
    ValioFooterLogosModule,
    ValioProductIconsModule,
    ValioOrderHistoryModule,
    ValioDeliveryNoteModule,
    ValioCarouselModule,
    ValioOrderTemplateModule,
    ValioOrderTemplateContentModule,
    ValioProductCarouselModule,
    ValioSearchBoxModule,
    ValioProductTooltipModule,
    ValioBannerModule,
    ValioThreeBoxBannerModule,
    ValioHeaderNewsModule,
    ValioPartnerCardModule,
    ValioNewsItemModule,
    ValioResetPasswordModule,
    ValioInputModule,
    ValioRouteCalendarModule,
    ValioLastPurchaseProductsModule,
    ValioCategoriesModule,
    ValioMiniCartModule,
    ValioOrderTemplatesModule,
    ValioSiteContextSelectorModule,
    ValioFaqModule,
    CmsParagraphModule,
    ValioPartnerApplyModule,
    ValioHamburgerMenuModule,
    ValioVoucherModule,
    ValioProductDeliveryPeriodModule,
    ValioEnvironmentModule,
    ValioVideoModule,
    ValioTopSellersModule,
    ValioTopSellerProductsModule,
    ValioBoughtTogetherModule,
    ValioBoughtTogetherProductsModule,
    UserAccountCoreModule,
    UserAccountOccModule,
    CheckoutModule
  ]
})
export class FeaturesModule {
}

