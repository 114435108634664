import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {CartDetailsModule} from "@spartacus/cart/base/components";
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {PromotionsModule} from "@spartacus/storefront";
import {ValioCarouselModule} from "../../../../shared/components/carousel/valio-carousel.module";
import {ValioProductListModule} from "../../valio-product-list.module";
import {ValioTopSellersComponent} from "./valio-top-sellers.component";


@NgModule({
    imports: [
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioTopSellersComponent: {
                    component: ValioTopSellersComponent,
                },
            },
        }),
        I18nModule,
        PromotionsModule,
        CommonModule,
        ValioProductListModule,
        ValioCarouselModule,
    ],
    declarations: [ValioTopSellersComponent],
    exports: [ValioTopSellersComponent]
})
export class ValioTopSellersModule extends CartDetailsModule {
}
