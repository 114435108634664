import {Component, OnDestroy, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsFooterComponent} from '../../../models';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ValioNavigationNode} from '../navigation/valio-navigation-node.model';
import {ValioNavigationService} from '../navigation/valio-navigation.service';
import {ValioUserService} from '../../../services/user/valio-user.service';
import {CmsService} from "@spartacus/core";

@Component({
  selector: 'valio-footer-component',
  templateUrl: './valio-footer.component.html',
})
export class ValioFooterComponent implements OnInit {
  isAnonymous$: Observable<boolean>;
  isSignedIn$: Observable<boolean>;
  languageComponent$: Observable<any>;
  data$ :Observable<ValioCmsFooterComponent>
  functionalityNavigationNode$: Observable<ValioNavigationNode>;
  contractsNavigationNode$: Observable<ValioNavigationNode>;
  instructionsNavigationNode$: Observable<ValioNavigationNode>;

  private componentData$: Observable<ValioCmsFooterComponent>;

  constructor(
    protected componentData: CmsComponentData<ValioCmsFooterComponent>,
    protected service: ValioNavigationService,
    protected userService: ValioUserService,
    protected cmsService: CmsService
  ) {
  }

  ngOnInit(): void {
    this.data$ = this.componentData.data$;
    this.isAnonymous$ = this.userService.isAnonymous();
    this.isSignedIn$ = this.userService.isSignedIn();
    this.languageComponent$ = this.cmsService.getComponentData<any>("LanguageComponent");
    this.componentData$= this.componentData.data$.pipe(
      filter(Boolean)
    );
    this.functionalityNavigationNode$=this.service.createNavigation(
      this.componentData$.pipe(
        map(data => data.navigationNode.children
          .filter(node => node.uid === 'FunctionalityNavNode')[0])
      )
    );
    this.contractsNavigationNode$=this.service.createNavigation(
      this.componentData$.pipe(
        map(data => data.navigationNode.children
          .filter(node => node.uid === 'ContractsNavNode')[0])
      )
    );
    this.instructionsNavigationNode$ = this.service.createNavigation(
      this.componentData$.pipe(
        map(data => data.navigationNode.children
          .filter(node => node.uid === 'InstructionsNavNode')[0])
      )
    );

  }






}
