import {Observable, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import {
  ConverterService,
  OccConfig,
  OccEndpointsService,
  OccRequestsOptimizerService,
  PRODUCT_NORMALIZER,
  ProductAdapter,
} from "@spartacus/core";
import {ValioCartService} from "../cart/valio-cart.service";
import {concatMap, filter, map, mergeMap} from "rxjs/operators";
import {Injectable, OnDestroy} from "@angular/core";
import {ValioOccEndpoints} from "../../valio-occ-endpoints";
import {ValioCategory, ValioProduct} from "../../models";
import {PRODUCT_LIST_NORMALIZER} from "./valio-product-list-normalizer";
import {ValioRestrictedAssortmentService} from "../site/valio-restricted-assortment.service";
import {ValioDatePipe} from "../pipes/valio-date.pipe";
import {ValioUser} from "../../models/misc.model";
import {UserAccountFacade} from '@spartacus/user/account/root';

@Injectable({
  providedIn: 'root',
})
export class ValioOccProductAdapter /*extends OccProductAdapter*/ implements ProductAdapter,OnDestroy {
  endpoints: ValioOccEndpoints;
  subscriptions: Subscription = new Subscription()

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected requestsOptimizer: OccRequestsOptimizerService,
    protected cartService: ValioCartService,
    protected cxDate: ValioDatePipe,
    protected config: OccConfig,
    protected userAccountFacade: UserAccountFacade,
    private restrictedAssortmentSiteService: ValioRestrictedAssortmentService
  ) {
   // super(http, occEndpoints, converter, requestsOptimizer);
    this.endpoints = this.config.backend.occ.endpoints;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }


  load(productCode: string, scope?: string): Observable<ValioProduct> {
    return this.getEndpointUrl('product', productCode, scope == 'toolTip').pipe(
      concatMap(url => {
          return this.http.get<ValioProduct>(url).pipe(this.converter.pipeable(PRODUCT_NORMALIZER));
        }
      )
    );
  }

  getManyProducts(productCodes: string[]): Observable<ValioProduct[]> {
    return this.getEndpointUrl('manyProducts', productCodes.join(',')).pipe(
      concatMap(url => {
          return this.http.get<ValioProduct[]>(url).pipe(this.converter.pipeable(PRODUCT_LIST_NORMALIZER));
        }
      )
    );
  }

  getCategoryById(categoryId: string): Observable<ValioCategory> {
    return this.http.get(this.occEndpoints.buildUrl('category', {urlParams: {categoryId}}
    ));
  }

  protected getEndpointUrl(endpoint: string, code: string, toolTip?: boolean): Observable<string> {
    return this.userAccountFacade.get().pipe(
      concatMap(user =>
        this.restrictedAssortmentSiteService.getActiveAsUrlParameterValue().pipe(
          mergeMap(assortment =>
            this.cartService.getRequestedDate()
              .pipe(
                filter(date => date),
                map(date =>
                  this.occEndpoints.buildUrl(endpoint, {
                      urlParams: {
                        productCode: code
                      }, queryParams:
                        {
                          requestedDate: this.cxDate.transform(date, this.endpoints.dateFormat),
                          assortment: assortment,
                          unit: (user as ValioUser)?.activeB2bUnit ? (user as ValioUser).activeB2bUnit.uid : '',
                          quickSearch: toolTip
                        }
                    }
                  )
                )
              )
          )
        )
      )
    );
  }
}
