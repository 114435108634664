import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener, Inject,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService, ProductSearchService, RoutingService, WindowRef} from '@spartacus/core';
import {
  BREAKPOINT,
  LayoutConfig,
  NavigationUIComponent,
  HamburgerMenuService
} from '@spartacus/storefront';
import {Router} from '@angular/router';
import {B2BUnit, ValioUser, Vendor} from '../../../models/misc.model';
import {ValioUserService} from '../../../services/user/valio-user.service';
import {CategoryHierarchy} from '../../../models';
import {ValioNavigationNode} from './valio-navigation-node.model';
import {ValioCart} from "../../../services/cart/valio-cart.objects";
import {ValioCategoriesService} from "../../../services/categories/valio-categories.service";
import {ValioCategoryDataService} from "../product/product-facet-navigation/valio-category-data.service";
import {ValioVendorService} from "../../../services/vendor/valio-vendor.service";

import {map, switchMap} from "rxjs/operators";
import {ValioPartnerSiteService} from "../../../services/site/valio-partner-site.service";
import {IGNORE_SEARCH_URL_PARAM} from "../product/container/valio-product-list-component.service";
import {ValioCartService} from "../../../services/cart/valio-cart.service";
import {ValioGoogleAnalyticsService} from "../../../services/analytics/valio-google-analytics.service";
import {ValioHamburgerMenuService} from '../../layout/hamburger-menu/valio-hamburger-menu.service';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {BreakPoint} from "@spartacus/storefront/layout/config/layout-config";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'valio-cx-navigation-ui',
  templateUrl: './valio-navigation-ui.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValioNavigationUIComponent extends NavigationUIComponent implements OnInit {

  @Input() headerNavigation = false;
  @Input() footerNavigation = false;
  @Input() mainNavigation = false;
  @Input() mainNavigationProducts = false;
  @Input() mainNavigationVendors = false;
  @Input() mainNavigationLink = false;
  @Input() declare node: ValioNavigationNode | null;
  @Input() cart: ValioCart;

  private valioOpenNodes: HTMLElement[] = [];

  user$: Observable<ValioUser>;
  mainCategories$: Observable<CategoryHierarchy>;
  childCategories$: Observable<CategoryHierarchy>;
  vendors$: Observable<Vendor[]>;
  lastSelectedCategory: string;
  lastSelectedCategoryName: string;
  lastSelectedCategoryFiName: string;
  showHeadCat: boolean;
  showProductsCat: boolean;
  showVendorsCat: boolean;
  isMobile: boolean;
  isAnonymous$: Observable<boolean>;
  isSignedIn$: Observable<boolean>;

  constructor(
    protected winRef: WindowRef,
    protected valioHamburgerMenuService: ValioHamburgerMenuService,
    protected router2: Router,
    protected renderer2: Renderer2,
    protected elemRef2: ElementRef,
    protected categoriesService: ValioCategoriesService,
    protected auth: AuthService,
    protected userService: ValioUserService,
    protected userAccountFacade: UserAccountFacade,
    protected cdr: ChangeDetectorRef,
    protected config: LayoutConfig,
    protected routerService: RoutingService,
    protected productSearchService: ProductSearchService,
    protected valioCategoryDataService: ValioCategoryDataService,
    protected vendorService: ValioVendorService,
    protected valioPartnerSiteService: ValioPartnerSiteService,
    protected cartService: ValioCartService,
    protected googleAnalyticsService: ValioGoogleAnalyticsService, hamburgerMenuService: HamburgerMenuService,
    @Inject(DOCUMENT) protected document: Document
  ) {
    super(router2, renderer2, elemRef2, hamburgerMenuService, winRef);
  }

  ngOnInit(): void {
    this.isAnonymous$ = this.userService.isAnonymous();
    this.isSignedIn$ = this.userService.isSignedIn();
    this.isMobile = this.getIsMobile();
    this.user$ = this.userAccountFacade.get().pipe(map(user => user as ValioUser))
    this.mainCategories$ = this.categoriesService.getCategories('', false);
    this.vendors$ = this.vendorService.getActiveVendors();
    this.showHeadCat = true;
    this.showProductsCat = false;
    this.showVendorsCat = false;
    this.document.defaultView.window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elemRef2.nativeElement.contains(event.target)) {
      const target = (<Element>this.elemRef2.nativeElement);
      this.renderer2.removeClass(target, 'is-open');
      for (let i = 0; i < target.childNodes.length; i++) {
        const childNode = <HTMLElement>target.childNodes[i];
        if (childNode.hasChildNodes() && childNode.classList.contains('is-open') && childNode.classList.contains('is-main-open')) {
          this.removeClasses(childNode);
        }
        if (childNode.hasChildNodes() && (childNode.classList.contains('is-open') || childNode.classList.contains('is-opened'))) {
          const childsSubmenu = <HTMLElement>childNode.querySelector('.childs-submenu');
          const personalDetailsNode = <HTMLElement>childsSubmenu.closest("nav");
          const myAccountNode = <HTMLElement>personalDetailsNode.parentElement.closest("nav");
          const units = <HTMLElement>myAccountNode.querySelector('.valio-units');
          this.valioToggleOpen(myAccountNode);
          if (personalDetailsNode.classList.contains('is-open') && units.classList.contains('d-none')) {
            this.closeChildsSubmenu(myAccountNode, personalDetailsNode, childsSubmenu, units);
          }
        }
      }
      this.clear();
    }

    if (this.isMobile) {
      const html = this.document.getElementsByTagName('html')[0];
      const storefront = this.document.getElementsByTagName('valio-cx-storefront')[0];
      if (storefront.classList.contains('is-expanded')) {
        this.renderer2.addClass(html, 'is-expanded');
      } else {
        this.renderer2.removeClass(html, 'is-expanded');
      }
    }
  }

  selectUnit(unit: B2BUnit) {
    this.googleAnalyticsService.customerChangeEvent(unit);
    this.cartService.selectUnitWithModal(unit.uid);
  }

  showHead(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.showHeadCat = true;
    this.showProductsCat = false;
    this.showVendorsCat = false;
  }

  showProducts(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.showHeadCat = false;
    this.showProductsCat = true;
  }

  showVendors(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.showHeadCat = false;
    this.showVendorsCat = true;
  }

  toggleChildCategory(id: string, name: string, fiName: string, event: MouseEvent) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    if (this.lastSelectedCategory !== id) {
      this.openCategory(id, true);
      this.lastSelectedCategory = id;
      this.valioHamburgerMenuService.lastSelectedCategory = id;
      this.lastSelectedCategoryName = name;
      this.lastSelectedCategoryFiName = fiName;
      this.childCategories$ = this.categoriesService.getCategories(id, false);
    } else {
      this.lastSelectedCategory = null;
      this.valioHamburgerMenuService.lastSelectedCategory = null;
      this.childCategories$ = null;
    }
    this.cdr.detectChanges();
  }

  openCategory(id: string, mainCategory?: boolean) {
    this.valioPartnerSiteService.reset();//partner needs to be reset, because not all partners have all categories visible
    if (mainCategory) {
      this.routerService.go('/search', {queryParams: {'query': ':relevance:category:' + id}});
      this.valioCategoryDataService.switchCategory('/category/' + id);
    } else {
      this.routerService.go('/search', {queryParams: {'query': ':relevance:category:' + id}});
      this.valioHamburgerMenuService.lastSelectedCategory = null;
    }
  }

  getIsMobile(): boolean {
    const w = this.document.documentElement.clientWidth;
    const breakpoint: number | BreakPoint = this.config.breakpoints[BREAKPOINT.md];
    if (typeof breakpoint === 'number') {
      return w < breakpoint;
    } else if (breakpoint && typeof breakpoint === 'object') {
      return w < (breakpoint.min || Number.MAX_VALUE);
    }
    return false;
  }

  toggleSubMenu(event: MouseEvent) {
    event.preventDefault();
    const node = <HTMLElement>event.currentTarget;
    const childsSubmenu = <HTMLElement>node.querySelector('.childs-submenu');
    const personalDetailsNode = <HTMLElement>childsSubmenu.closest("nav");
    const myAccountNode = <HTMLElement>personalDetailsNode.parentElement.closest("nav");
    const units = <HTMLElement>myAccountNode.querySelector('.valio-units');
    if (node.isEqualNode(personalDetailsNode)) {
      if (personalDetailsNode.classList.contains('is-open')) {
        this.closeChildsSubmenu(myAccountNode, personalDetailsNode, childsSubmenu, units);
      } else {
        this.openChildsSubmenu(myAccountNode, personalDetailsNode, childsSubmenu, units);
      }
    } else {
      this.valioToggleOpen(node);
      this.valioUpdateClasses();
      this.closeChildsSubmenu(myAccountNode, personalDetailsNode, childsSubmenu, units);
    }
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  valioToggleOpen(node: HTMLElement): void {
    if (this.valioOpenNodes.includes(node)) {
      this.valioOpenNodes = this.valioOpenNodes.filter((n) => n !== node);
      this.renderer2.removeClass(node, 'is-open');
    } else {
      this.valioOpenNodes.push(node);
    }
  }

  valioUpdateClasses(): void {
    this.valioOpenNodes.forEach((node, i) => {
      if (i + 1 < this.valioOpenNodes.length) {
        this.renderer2.addClass(node, 'is-opened');
        this.renderer2.removeClass(node, 'is-open');
      } else {
        this.renderer2.removeClass(node, 'is-opened');
        this.renderer2.addClass(node, 'is-open');
      }
    });
    this.isOpen = this.valioOpenNodes.length > 0;
  }

  closeChildsSubmenu(myAccountNode: Element, personalDetailsNode: Element, childsSubmenu: Element, units: Element): void {
    this.renderer2.removeClass(myAccountNode, 'is-opened');
    this.renderer2.removeClass(personalDetailsNode, 'is-open');
    this.renderer2.removeClass(units, 'd-none');
    this.renderer2.addClass(childsSubmenu, 'd-none');
  }

  openChildsSubmenu(myAccountNode: Element, personalDetailsNode: Element, childsSubmenu: Element, units: Element): void {
    this.renderer2.addClass(myAccountNode, 'is-opened');
    this.renderer2.addClass(personalDetailsNode, 'is-open');
    this.renderer2.addClass(units, 'd-none');
    this.renderer2.removeClass(childsSubmenu, 'd-none');
  }

  switchPartner(vendor: Vendor) {
    // force page reload
    this.router2.navigateByUrl('/?' + IGNORE_SEARCH_URL_PARAM, {skipLocationChange: true}).then(() => {
      this.valioPartnerSiteService.setActive(vendor.code);
      this.routerService.go('/search');
    });
  }

  getCurrentPartner(): Observable<string> {
    return this.valioPartnerSiteService.getActive().pipe(
      switchMap(code => this.vendorService.getVendor(code).pipe(
        map(vendor => vendor ? vendor.name : null)
      ))
    );
  }

  toggleMainOpen(event: UIEvent): void {
    if (event.type === 'keydown') {
      event.preventDefault();
    }

    const node = <HTMLElement>event.currentTarget;
    if (event.type === 'keydown') {
      this.back();
    } else {
      if (node.classList.contains('is-open') && node.classList.contains('is-main-open')) {
        this.removeClasses(node);
      } else {

        this.addClasses(node);
      }
    }
    let mainNavs = this.document.getElementsByClassName('is-main-open');
    for (let i = 0; i < mainNavs.length; i++) {
      if (mainNavs[i] && mainNavs[i].id !== node.id) {
        this.removeClasses(mainNavs[i]);
      }
    }
    event.stopImmediatePropagation();
    event.stopPropagation();
  }


  removeClasses(node: Element) {
    this.renderer2.removeClass(node.parentNode, 'is-open');
    this.renderer2.removeClass(node, 'is-open');
    this.renderer2.removeClass(node, 'is-main-open');
  }

  addClasses(node: Element) {
    this.renderer2.addClass(node.parentNode, 'is-open');
    this.renderer2.addClass(node, 'is-open');
    this.renderer2.addClass(node, 'is-main-open');
  }
}
