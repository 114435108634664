import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AuthRedirectService,
  AuthService,
  FeatureConfigService,
  GlobalMessageEntities,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService, User,
} from "@spartacus/core";
import {BehaviorSubject, Subscription} from 'rxjs';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import {filter} from "rxjs/operators";
import {ValioUser, ValioUserSignUp} from "../../../../models/misc.model";
import {CustomFormValidators} from "@spartacus/storefront";
import VatIdValidator from "../../../../shared/utils/validators/VatIdValidator";
import {UserIdValidator} from "../../../../shared/utils/validators/UserIdValidator";
import {ValioGoogleAnalyticsService} from "../../../../services/analytics/valio-google-analytics.service";
import {UserRegisterFacade} from "@spartacus/user/profile/root";

const pwValidationPattern = /^(?=.*?[a-zöäå])(?=.*?[A-ZÖÄÅ])(?=.*?[0-9]).{6,}$/;

@Component({
  selector: 'valio-cx-register-existing',
  templateUrl: './valio-register-existing.component.html',
})
export class ValioRegisterExistingComponent implements OnInit, OnDestroy {


  private subscription = new Subscription();
  showStep1: boolean = true;
  showStep2: boolean;
  showThanks = false;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  userRegistrationFormStep1: UntypedFormGroup;

  userRegistrationFormStep2: UntypedFormGroup;

  // TODO(issue:4237) Register flow
  isNewRegisterFlowEnabled: boolean=false;

  private submittedStep1 = false;
  /**
   * @deprecated since 1.1.0
   *
   * TODO(issue:4237) Register flow
   */
  constructor(
    protected auth: AuthService,
    protected authRedirectService: AuthRedirectService,
    protected userRegisterFacade: UserRegisterFacade,
    protected globalMessageService: GlobalMessageService,
    protected fb: UntypedFormBuilder,
    protected userIdValidator: UserIdValidator,
    protected googleAnalyticsService: ValioGoogleAnalyticsService,
    protected router?: RoutingService,
    protected featureConfig?: FeatureConfigService
  ) {
  }
  ngOnInit() {
    this.userRegistrationFormStep1 =this.fb.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, CustomFormValidators.emailValidator]],
        userId: new UntypedFormControl('', {
          validators: Validators.required,
          asyncValidators: this.userIdValidator.validate.bind(this.userIdValidator),
          updateOn: 'blur'
        }),
        phoneNumber: ['+358', ValioRegisterExistingComponent.phoneNumberValidatorForCms],
        password: [
          '',
          [Validators.required, ValioRegisterExistingComponent.passwordValidator],
        ],
        passwordconf: ['', Validators.required],
        termsandconditions: [false, Validators.requiredTrue]
      },
      {validators: [this.matchPassword]}
    );
    this.userRegistrationFormStep2= this.fb.group(
      {
        vatId: ['', [Validators.required, VatIdValidator.valid]],
        customerNumbersGroup: this.fb.array([]),
        companyName: ['', Validators.required],
        companyNameDepartment: ['', Validators.required],
      },
      {}
    );


    // TODO(issue:4237) Register flow
    this.isNewRegisterFlowEnabled=this.featureConfig && this.featureConfig.isLevel('1.1');

    // TODO(issue:4237) Register flow
    if (this.isNewRegisterFlowEnabled) {
      this.isLoading$.next(false);
    } else {
      if (this.auth && this.authRedirectService) {
        this.subscription.add(
          this.auth.isUserLoggedIn().subscribe(isLogged => {
            if (isLogged) {
              this.globalMessageService.remove(
                GlobalMessageType.MSG_TYPE_ERROR
              );
              this.authRedirectService.redirect();
            }
          })
        );
      }
    }

    // TODO: Workaround: allow server for decide is titleCode mandatory (if yes, provide personalized message)
    this.subscription.add(
      this.globalMessageService
        // TODO:Spartacus - Method 'get' was removed from Class 'UserService'. Use 'UserAccountFacade.get()' from '@spartacus/user' instead.
        .get()
        .pipe(filter(messages => !!Object.keys(messages).length))
        .subscribe((globalMessageEntities: GlobalMessageEntities) => {
          const messages =
            globalMessageEntities &&
            globalMessageEntities[GlobalMessageType.MSG_TYPE_ERROR];

          if (
            messages &&
            messages.some(message => message.raw === 'This field is required.')
          ) {
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.add(
              {key: 'register.titleRequired'},
              GlobalMessageType.MSG_TYPE_ERROR
            );
          }
        })
    );
  }
  public static passwordValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.value as string;
    return password.match(pwValidationPattern) ? null : {InvalidPassword: true};
  }

  public static phoneNumberValidatorForCms(control: AbstractControl): ValidationErrors | null {
    const phone = control.value as string;
    const strippedPhone: any = phone.replace('+', '').replace('-', '').replace(/ /g, '');
    return phone && phone.startsWith('+') && phone.length >= 9 && phone.length <= 30 && !isNaN(strippedPhone) ? null : {InvalidPhone: true};
  }

  public static phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const phone = control.value as string;
    return phone && phone.length >= 5 ? null : {InvalidPhone: true};
  }



  submittedStep2 = false;

  submit(): void {
    if (this.userRegistrationFormStep2.invalid) {
      this.submittedStep2 = true;
      return;
    }
    this.isLoading$.next(true);
    this.subscription.add(
      this.userRegisterFacade.register(
        this.collectDataFromRegisterForm(this.userRegistrationFormStep1.value, this.userRegistrationFormStep2.value)).subscribe((user: User) => this.doOnRegisterUserSuccess(user),
          error => this.onError(error),
        () => this.onComplete()
      ));
  }
  private doOnRegisterUserSuccess(user: ValioUser): void {
    if (this.isNewRegisterFlowEnabled) {
      this.onRegisterUserSuccess(true);
    } else {
      if (this.auth && this.authRedirectService) {
        this.googleAnalyticsService.createRegisterEvent(true);
        const {uid, password} = this.collectDataFromRegisterForm(
          this.userRegistrationFormStep1.value, this.userRegistrationFormStep2.value
        );
        this.auth.loginWithCredentials(uid, password).then(value => console.debug(`Logged in: ${uid}`));
      }
    }
  }
  private onComplete() {
    this.isLoading$.next(false)
  }

  private onError(error:any): void {
    if (error) {
      console.error(JSON.stringify(error));
    }
    this.isLoading$.next(false);
  }
  collectDataFromRegisterForm(formDataStep1: any, formDataStep2: any): ValioUserSignUp {
    const {firstName, lastName, email, userId, phoneNumber, password} = formDataStep1;
    const {customerNumbersGroup, companyName, vatId, companyNameDepartment} = formDataStep2;
    return {
      firstName,
      lastName,
      email,
      password,
      uid: userId,
      phoneNumber,
      vendors: customerNumbersGroup.map(b => b.vendor),
      customerNumbers: customerNumbersGroup.map(b => b.customerNumber),
      companyName,
      companyNameDepartment,
      vatId
    };
  }

  private onRegisterUserSuccess(success: boolean): void {
    if (success) {
      this.googleAnalyticsService.createRegisterEvent(true);
      this.showThanksContainer();
    }
  }

  private matchPassword(ac: AbstractControl): { NotEqual: boolean } {
    if (ac.get('password').value !== ac.get('passwordconf').value) {
      return {NotEqual: true};
    }
    return null;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }



  goToFirstStep() {
    this.showStep1 = true;
    this.showStep2 = false;
  }

  goToSecondStep() {
    if (!this.userRegistrationFormStep1.valid) {
      this.submittedStep1 = true;
      return;
    }
    this.showStep1 = false;
    this.showStep2 = true;
  }

  showThanksContainer() {
    this.showStep1 = false;
    this.showStep2 = false;
    this.showThanks = true;
  }


  goToHomePage() {
    this.router.go('/');
  }

  isInvalidStep1(fieldName: string): boolean {
    return this.submittedStep1 && this.userRegistrationFormStep1.get(fieldName).invalid;
  }

  isInvalidStep2(fieldName: string): boolean {
    return this.submittedStep2 && this.userRegistrationFormStep2.get(fieldName).invalid;
  }

  validateForm() {
    this.userRegistrationFormStep1.controls['userId'].updateValueAndValidity();
  }
}
