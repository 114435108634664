import {Inject, Injectable, OnDestroy} from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {ActiveCartService, getCartIdByUserId, StateWithMultiCart} from "@spartacus/cart/base/core";
import {DeliveryMode, MultiCartFacade} from '@spartacus/cart/base/root';
import {OAuthLibWrapperService, RoutingService, UserIdService, WindowRef} from "@spartacus/core";
import {Observable, Subscription} from "rxjs";
import {concatMap, map, take, withLatestFrom} from "rxjs/operators";
import {ValioCalendarDataService} from "../../features/calendar/valio-calendar-data.service";
import {DateUtils} from "../../features/misc/util/date-utils";
import {ValioAddress} from "../address/address-model";
import {ValioDatePipe} from "../pipes/valio-date.pipe";
import {ValioBoughtTogetherService} from "../recommendations/bought-together/valio-bought-together.service";
import {ValioUserService} from "../user/valio-user.service";
import {openCloseSpinner} from "../util/valio-modals-utils";
import {ValioAddEmailToCart, ValioCartAddEntry, ValioCartChangeHeader, ValioCartCheckout, ValioCartSimulate, ValioCartUpdateEntry, ValioRemoveCartDate} from "./valio-cart-entry.action";
import {ValioCart, ValioCartEntry, ValioCartEntryUpdateData, ValioCartModification} from "./valio-cart.objects";
import {getCsvImportContent, ValioCsvImport, ValioCsvImportReset, ValioStateWithCsvImport} from "./valio-csv-import.action";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {ValioLogoutDialogData} from "../../features/shared/components/logout/valio-logout-modal-layout.config";
import {ValioCartConnector} from "./valio-cart.connector";
import {DOCUMENT} from "@angular/common";


@Injectable({
  providedIn: 'root',
})
export class ValioCartService extends ActiveCartService implements OnDestroy {
  modalOpened: boolean;
  currentDay: Date;
  subscriptions: Subscription = new Subscription();


  constructor(protected store: Store<StateWithMultiCart>,
              protected cartModificationStore: Store<ValioStateWithCsvImport>,
              protected userIdService: UserIdService,
              protected cxDate: ValioDatePipe,
              protected routingService: RoutingService,
              protected valioCalendarDataService: ValioCalendarDataService,
              protected userService: ValioUserService,
              protected router: Router,
              protected boughtTogetherService: ValioBoughtTogetherService,
              protected multiCartFacade: MultiCartFacade,
              protected launchDialogService: LaunchDialogService,
              protected cartConnector: ValioCartConnector,
              protected oAuthLibWrapperService: OAuthLibWrapperService, windowRef: WindowRef,
              @Inject(DOCUMENT) private document: Document) {
    super(multiCartFacade, userIdService, windowRef);
    this.getActive()
      .pipe(withLatestFrom(this.userIdService.takeUserId(), this.router.events))
      .subscribe(([cart, userId, event]) => {
        if (event instanceof NavigationStart) {
          if (event.url.endsWith('/cart')) {
            this.store.dispatch(
              new ValioCartSimulate(userId, getCartIdByUserId(cart, userId))
            );
          }
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    super.ngOnDestroy();
  }

  hasUiCart(negate?: boolean): Observable<boolean> {
    return this.userService.isAnonymous()
      .pipe(
        concatMap(anonUser => this.getActive().pipe(
          map(cart => {
              const ret = (!anonUser || cart.dateGroupedEntries?.filter(e => e.deliveryDate == cart.requestedDate)
                .filter(e => e.entries.length > 0).length > 0);
              return negate ? !ret : ret;
            }
          )
        ))
      );
  }

  getRequestedDate(): Observable<any> {
    return this.getActive().pipe(
      map(
        c => c.code ? c.requestedDate ? new Date(c.requestedDate) : null : DateUtils.getNow()
      )
    );
  }

  changeDate(deliveryDate: string): void {
    this.updateHeader('deliveryDate', deliveryDate.replace(/T.*/, ''));//change format without time
  }

  changeSoldtoAddress(address: ValioAddress): void {
    this.updateHeader('paymentAddress', null, address);
  }

  changeShiptoAddress(address: ValioAddress): void {
    this.updateHeader('deliveryAddress', null, address);
  }

  changeDeliveryMode(dm: DeliveryMode): void {
    this.updateHeader('deliveryMode', dm.code);
  }

  changePickupLocation(id: string): void {
    this.updateHeader('pickupLocation', id);
  }

  updateHeader(field: string, value: any, body?: any) {
    const sub = this.requireLoadedCart()
      .pipe(
        withLatestFrom(this.userIdService.takeUserId()),
        take(1),
      )
      .subscribe(([cart, userId]) => {
          this.store.dispatch(
            new ValioCartChangeHeader({
              userId: userId,
              cartId: getCartIdByUserId(cart, userId),
              field: field,
              value: value,
              body: body
            })
          );
        }
      );
    setTimeout(() => sub.unsubscribe(), 100);
  }

  addEmail(email: string): void {
    this.requireLoadedCart()
      .pipe(
        take(1),
        withLatestFrom(this.userIdService.takeUserId()))
      .subscribe(([cart, userId]) =>
        this.store.dispatch(
          new ValioAddEmailToCart({
            userId: userId,
            cartId: getCartIdByUserId(cart, userId),
            email: email,
          })
        )
      ).unsubscribe();
  }

  addValioEmail(cart: ValioCart, email: string, userId: string) {
    this.store.dispatch(
      new ValioAddEmailToCart({
        userId: userId,
        cartId: getCartIdByUserId(cart, userId),
        email: email,
      })
    )
  }


  getLoaded(): Observable<boolean> {
    return super.isStable();
  }

  getActive(): Observable<ValioCart> {
    return super.getActive() as Observable<ValioCart>;
  }

  checkout(date: Date, toCreditPage: boolean) {
    const sub = this.getActive()
      .pipe(withLatestFrom(this.userIdService.takeUserId()))
      .subscribe(([cart, userId]) => {
        openCloseSpinner(this.document, true);

        this.store.dispatch(
          new ValioCartCheckout(
            userId,
            getCartIdByUserId(cart, userId),
            this.cxDate.transform(date, 'yyyy-MM-dd'),
            toCreditPage
          )
        );
        setTimeout(() => sub.unsubscribe(), 1000);
      });
  }

  updateEntryData(entryNumber: string, data: ValioCartEntryUpdateData, cartId?: string): void {
    const sub = this.requireLoadedCart()
      .pipe(take(1), withLatestFrom(this.userIdService.takeUserId()))
      .subscribe(([cart, userId]) => {
          this.store.dispatch(
            new ValioCartUpdateEntry({
              userId: userId,
              cartId: getCartIdByUserId(cart, userId),
              entry: entryNumber,
              data: data,
            })
          );
          this.boughtTogetherService.loadBoughtTogether(null, getCartIdByUserId(cart, userId), entryNumber);
          setTimeout(() => sub.unsubscribe(), 1000);
        }
      );
  }

  removeCartDate(date: string): void {
    const sub = this.requireLoadedCart()
      .pipe(take(1), withLatestFrom(this.userIdService.takeUserId()))
      .subscribe(([cart, userId]) => {
          this.store.dispatch(
            new ValioRemoveCartDate(
              userId,
              getCartIdByUserId(cart, userId),
              date
            ));
          setTimeout(() => sub.unsubscribe(), 1000);
        }
      );
  }

  switchPayment(partner: string) {
    this.updateHeader("partner", partner);
  }

  selectUnitWithModal(unit: string): void {
    this.currentDay = DateUtils.getNow();
    const date = this.cxDate.transform(this.currentDay, 'yyyy-MM-dd');
    this.subscriptions.add(
      this.getActive()
        .pipe(take(1))
        .subscribe(cart => {
            this.modalOpened = false;
            if (!cart.dateGroupedEntries || cart.dateGroupedEntries?.map(entry => {
                if (entry.validForOrdering && !this.modalOpened) {
                  this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.LOGOUT_MODAL, null, {
                    logoutModal: false,
                    unit: unit
                  } as ValioLogoutDialogData);
                  this.modalOpened = true;
                  return true;
                }
              }
            ).filter(f => f === true).length == 0) {
              this.userService.selectUnit(unit)
            }
          }
        )
    );
  }

  addEntryToCart(productCode: string, data: ValioCartEntryUpdateData): void {
    const sub = this.requireLoadedCart()
      .pipe(take(1), withLatestFrom(this.userIdService.takeUserId()))
      .subscribe(([cart, userId]) => {
        this.store.dispatch(
          new ValioCartAddEntry(
            userId,
            getCartIdByUserId(cart, userId),
            productCode,
            data
          ));
        this.boughtTogetherService.loadBoughtTogether(productCode, getCartIdByUserId(cart, userId), null);
        this.valioCalendarDataService.activateMiniCart(true);
        setTimeout(() => sub.unsubscribe(), 1000);
      });
  }


  addEntry(productCode, quantity) {
    this.addEntryToCart(productCode, {qty: quantity} as ValioCartEntryUpdateData);
  }

  getCsvImportCartModifications(): Observable<ValioCartModification[]> {
    return this.cartModificationStore.pipe(
      select(getCsvImportContent),
      map(details => {
        return details;
      })
    );
  }

  resetCsvImportCartModifications(): void {
    this.cartModificationStore.dispatch(new ValioCsvImportReset());
    this.subscriptions.add(
      this.requireLoadedCart()
        .pipe(take(1),
          withLatestFrom(this.userIdService.takeUserId()))
        .subscribe(([cart, userId]) =>
          this.store.dispatch(new ValioCartSimulate(userId, getCartIdByUserId(cart, userId)))
        ));
  };

  addCsvEntriesToCart(data: ValioCartEntry[]): void {
    const sub = this.requireLoadedCart()
      .pipe(take(1),
        withLatestFrom(this.userIdService.takeUserId()))
      .subscribe(([cart, userId]) => {
        openCloseSpinner(this.document, true);
        this.cartModificationStore.dispatch(
          new ValioCsvImport(
            userId,
            getCartIdByUserId(cart, userId),
            data
          ));
        if (data.length > 0) {
          this.boughtTogetherService.loadBoughtTogether(data[data.length - 1].product.code, getCartIdByUserId(cart, userId), null);
        }
        this.valioCalendarDataService.activateMiniCart(true);
        setTimeout(() => sub.unsubscribe(), 1000);
      });
  }
}

