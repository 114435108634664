
export function openCloseSpinner(doc: Document,
                                 show: boolean) {
  const node = doc?.getElementById('valio-spinner');
  if (node && node.classList) {
    if (show) {
      node.classList.add('show');
    } else {
      node.classList.remove('show');
    }
  }
}
