import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {createFeatureSelector, createSelector, select, Store} from "@ngrx/store";
import {ANALYTICS_FEATURE, ValioEnvironmentState} from "./valio-environment-state";
import {LoadAnalytics} from "./valio-environment.action";
import {AnalyticsConfig} from "./valio-environment.objects";


@Injectable({
  providedIn: 'root',
})
export class ValioEnvironmentService {

  constructor(protected store: Store<ValioEnvironmentState>) {
  }

  analyticsConfig(): Observable<AnalyticsConfig> {
    return this.store
      .pipe(
        select(
          createSelector(
            createFeatureSelector<ValioEnvironmentState>(ANALYTICS_FEATURE), state => {
              return state?.analyticsConfig
            }
          )
        )
      ).pipe(tap(q => {
        if (!q || Object.keys(q).length === 0) {
          this.loadAnalytics();
        }
      }));
  }

  private loadAnalytics() {
    this.store.dispatch(new LoadAnalytics());
  }
}

