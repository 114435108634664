import {Inject, Injectable, OnDestroy,PLATFORM_ID} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { OrderEntry } from "@spartacus/cart/base/root";
import { OCC_USER_ID_ANONYMOUS } from "@spartacus/core";
import { Order } from "@spartacus/order/root";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import {Subject, Subscription} from "rxjs";
import {filter, map, takeUntil} from 'rxjs/operators';
import { Md5 } from "ts-md5";
import { Breadcrumb, ValioProduct, ValioProductSearchPage } from "../../models";
import { B2BUnit, ValioUser, Vendor } from "../../models/misc.model";
import { Partner, ValioBaseOrder, ValioBaseOrderEntry, ValioCart, ValioCartEntry } from "../cart/valio-cart.objects";
import { ValioOrder } from "../checkout/valio-checkout.module";
import { ValioOrderTemplatesService } from "../orderTemplates/valio-order-templates.service";
import { ValioDatePipe } from "../pipes/valio-date.pipe";
import { ValioPartnerService } from "../vendor/valio-partner.service";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";



@Injectable({
  providedIn: 'root'
}
)
export class ValioGoogleAnalyticsService implements OnDestroy {


  private subscriptions: Subscription = new Subscription();
  private user: ValioUser;
  private vendor: Vendor;
  private debug: boolean = false;

  constructor(protected userAccountFacade: UserAccountFacade,
    protected partnerService: ValioPartnerService,
    protected router: Router,
    protected cxDate: ValioDatePipe,
    protected orderTemplatesService: ValioOrderTemplatesService,
    protected gtmService: GoogleTagManagerService,
    protected activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) protected document: Document,
              @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    //super(googleTagManagerConfig, googleTagManagerId, googleTagManagerAuth, googleTagManagerPreview,googleTagManagerResourcePath,googleTagManagerCSPNonce);
    const gtmUrlParam = this.activatedRoute.snapshot.queryParamMap.get('_gl');
    this.addToDom(gtmUrlParam);

    this.subscriptions.add(this.userAccountFacade.get()
      .pipe(
        filter(user => user != undefined),
        map(user1 => user1 as ValioUser)
      )
      .subscribe(user1 => {

        if (this.isLoginEventRequired(user1)) {
          // Logged in if anonymous changes to something else
          this.loginEvent(user1);
        }
        this.user = user1;
      }));
    this.subscriptions.add(this.partnerService.getSelectedVendor().subscribe(vendor1 => this.vendor = vendor1));
  }
  pushTag(item: object) {
    this.gtmService.pushTag(item)
      .then(ret => {
        if (this.debug) {
          console.info("GTM:" + JSON.stringify(item))
        }
      });
  }
  public addToDom(gtmUrlParam: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.gtmService.addGtmToDom().then(ret => {
        if (this.debug) {
          console.info("GtmToDom: " + ret)
        }
      });
    }
    if (gtmUrlParam) {
      this.pushTag({ crossDomain: gtmUrlParam });
    }
  }

  removeFromCartEvent(product: ValioProduct, amount: number) {
    this.pushTag({
      'event': 'ec:removeFromCart',
      'ecommerce': this.createECommerce(product, amount, false)
    });
  }

  addToCartEvent(product: ValioProduct, amount: number) {
    this.pushTag({
      'event': 'ec:addToCart',
      'ecommerce': this.createECommerce(product, amount, true)
    });
  }

  public cartEvent(product: ValioProduct, amount: number) {
    if (amount > 0) {
      this.addToCartEvent(product, amount);
    } else if (amount < 0) {
      this.removeFromCartEvent(product, amount);
    }
  }

  private createECommerce(product: ValioProduct, amount: number, isAdd: boolean): any {
    return {
      'currencyCode': ValioGoogleAnalyticsService.getCurrencyIso(product),
      [isAdd ? 'add' : 'remove']: {
        'actionField': {
          'list': ValioGoogleAnalyticsService.getList(product)
        },
        'products': [{
          'name': ValioGoogleAnalyticsService.getName(product),
          'id': ValioGoogleAnalyticsService.getId(product),
          'price': ValioGoogleAnalyticsService.getPrice(product),
          'quantity': Math.abs(amount),
          'brand': ValioGoogleAnalyticsService.getBrand(product),
          'category': ValioGoogleAnalyticsService.getCategory(product),
          'variant': ValioGoogleAnalyticsService.getVariant(product),
          'position': ValioGoogleAnalyticsService.getPosition(product),
          'dimension1': ValioGoogleAnalyticsService.getDimension1(product),
          'dimension2': ValioGoogleAnalyticsService.getDimension2(product),
          'dimension3': ValioGoogleAnalyticsService.getDimension3(product),
          'dimension4': ValioGoogleAnalyticsService.getDimension4(product),
          'dimension5': ValioGoogleAnalyticsService.getDimension5(product),
          'dimension7': ValioGoogleAnalyticsService.getDimension7(this.user),
          'dimension13': ValioGoogleAnalyticsService.getDimension13(this.vendor),
          'dimension14': ValioGoogleAnalyticsService.getDimension14(product),
          'dimension15': ValioGoogleAnalyticsService.getDimension15(product)
        }]
      }
    }
  }

  loginEvent(user1: ValioUser) {
    if (ValioGoogleAnalyticsService.isLoggedIn(user1)) {
      if (user1 && user1.activeB2bUnit) {
        this.pushTag({
          'event': 'login',
          'dimension6': ValioGoogleAnalyticsService.getDimension6FromUser(user1),
          'dimension10': ValioGoogleAnalyticsService.getDimension10FromUser(user1),
          'dimension11': this.getDimension11(user1)
        });
      }
    }
  }

  customerChangeEvent(unit: B2BUnit) {
    if (unit) {
      this.pushTag({
        'event': 'changeCustomerNumber',
        'dimension6': ValioGoogleAnalyticsService.getDimension6FromB2bUnit(unit),
        'dimension10': ValioGoogleAnalyticsService.getDimension10FromB2bUnit(unit)
      });
    }

  }

  createProductDetailViewEvent(product: ValioProduct) {
    if (product) {
      this.pushTag({
        'event': 'ec:details',
        'ecommerce': {
          'detail': {
            'products': [{
              'name': ValioGoogleAnalyticsService.getName(product),
              'id': ValioGoogleAnalyticsService.getId(product),
              'price': ValioGoogleAnalyticsService.getPrice(product),
              'brand': ValioGoogleAnalyticsService.getBrand(product),
              'category': ValioGoogleAnalyticsService.getCategory(product),
              'variant': ValioGoogleAnalyticsService.getVariant(product),
              'dimension1': ValioGoogleAnalyticsService.getDimension1(product),
              'dimension2': ValioGoogleAnalyticsService.getDimension2(product),
              'dimension3': ValioGoogleAnalyticsService.getDimension3(product),
              'dimension4': ValioGoogleAnalyticsService.getDimension4(product),
              'dimension5': ValioGoogleAnalyticsService.getDimension5(product),
              'dimension7': ValioGoogleAnalyticsService.getDimension7(this.user),
              'dimension13': ValioGoogleAnalyticsService.getDimension13(this.vendor),
              'dimension14': ValioGoogleAnalyticsService.getDimension14(product),
              'dimension15': ValioGoogleAnalyticsService.getDimension15(product)
            }]
          }
        }
      });
    }
  }

  createCarouselImpressionEvent(products: ValioProduct[], listName: string, start: number, end: number) {
    if (products) {
      // Carousel component, no filters - use predefined string as instructed
      const filters: string = 'toimittaja,pakkaukset,erityisruokavaliot,muut suodattimet';
      const impressions = products
        .map(product => {
          if (product && product.code) {
            return this.createProductImpression(product, filters);
          }
          return undefined;
        })
        .slice(start, end)
        .filter(impression => impression && impression.name);

      this.pushTag({
        'event': 'ec:impressions',
        'ecommerce': {
          'impressions': impressions
        }
      });
    }
  }

  createImpressionEventFromSearchPage(searchPage: ValioProductSearchPage) {
    if (searchPage) {
      // Non-zero based index
      const listIndex: number = searchPage.pagination.currentPage * searchPage.pagination.pageSize + 1;
      const products: ValioProduct[] = searchPage.products;
      const listName: string = ValioGoogleAnalyticsService.getSearchPageListName(searchPage);
      this.pushTag({
        'event': 'ec:impressions',
        'ecommerce': {
          'impressions': products.map((product, index) => {
            return {
              ...product,
              gaList: { position: index + listIndex, listName: listName } // The actual search string not wanted
            }
          })
            .map(product => {
              return this.createProductImpression(product, ValioGoogleAnalyticsService.getSelectedFilters(searchPage))
            })
        }
      });
    }
  }

  private static getSearchPageListName(searchPage: ValioProductSearchPage): string {
    // Filter 'all products' text search without category selection has breadcrumb with search key as facet code and value.
    const breadcrumbs: Breadcrumb[] = searchPage.breadcrumbs?.filter(br => br.facetValueCode != br.facetValueName);
    if (breadcrumbs && breadcrumbs.length > 0) {
      const lastBreadCrumb = breadcrumbs[breadcrumbs.length - 1];
      if (searchPage.freeTextSearch && searchPage.freeTextSearch.length > 0) {
        return 'haku - ' + lastBreadCrumb.facetValueName;
      }
      return lastBreadCrumb.facetValueName;
    }
    return undefined;

  }

  /* Uncomment to see data pushed to GA */

  /*
    pushTag(item: object) {
      console.info("PUSH", item);
      super.pushTag(item);
    }
  */

  private createProductImpression(product: ValioProduct, selectedFilters: string) {
    return {
      'name': ValioGoogleAnalyticsService.getName(product),
      'id': ValioGoogleAnalyticsService.getId(product),
      'price': ValioGoogleAnalyticsService.getPrice(product),
      'brand': ValioGoogleAnalyticsService.getBrand(product),
      'category': ValioGoogleAnalyticsService.getCategory(product),
      'variant': ValioGoogleAnalyticsService.getVariant(product),
      'list': ValioGoogleAnalyticsService.getList(product),
      'position': ValioGoogleAnalyticsService.getPosition(product),
      'dimension1': ValioGoogleAnalyticsService.getDimension1(product),
      'dimension2': ValioGoogleAnalyticsService.getDimension2(product),
      'dimension3': ValioGoogleAnalyticsService.getDimension3(product),
      'dimension4': ValioGoogleAnalyticsService.getDimension4(product),
      'dimension5': ValioGoogleAnalyticsService.getDimension5(product),
      'dimension7': ValioGoogleAnalyticsService.getCustomerType(this.user),
      'dimension12': selectedFilters,
      'dimension13': ValioGoogleAnalyticsService.getDimension13(this.vendor),
      'dimension14': ValioGoogleAnalyticsService.getDimension14(product),
      'dimension15': ValioGoogleAnalyticsService.getDimension15(product)
    }
  }


  private static getSelectedFilters(searchPage: ValioProductSearchPage): string {
    return searchPage.facets.map(facet => {
      const selected: string = facet.values.filter(facetValue => facetValue.selected)
        .map(facetValue => facetValue.name)
        .join(':');
      // Return selected value(s) or filter name if none selected
      return selected.length > 0 ? selected : facet.name;
    }).join(',');
  }

  createApplyForContractCustomerEvent(partner: Partner): void {
    this.pushTag({
      'event': 'applyForContractCustomer',
      'eventCategory': 'hae sopimusasiakkaaksi',
      'eventAction': 'hakemus lähetetty',
      'eventLabel': partner.name //'#supplier_name#'
    }
    );
  }

  createRegisterEvent(hasContract: boolean): void {
    this.pushTag({
      'event': 'registration',
      'eventCategory': 'rekisteröinti',
      'eventAction': 'onnistunut rekisteröinti',
      'eventLabel': hasContract ? 'sopimusasiakas' : 'ei sopimusta'
    });

  }

  private static getCategoryLevel(product: ValioProduct, index: number): string {
    return product && product.categories && product.categories.length > index ? product.categories[index].name : undefined;
  }

  private static getCategory(product: ValioProduct): string {
    const path: string = [
      ValioGoogleAnalyticsService.getCategoryLevel(product, 2),
      ValioGoogleAnalyticsService.getCategoryLevel(product, 1),
      ValioGoogleAnalyticsService.getCategoryLevel(product, 0)
    ].filter(category => category)
      .join(' > ');
    if (path && path.length > 0) {
      return path;
    }
    return undefined;
  }


  public static getCustomerType(user: ValioUser): string {
    if (user && user.activeB2bUnit && ValioGoogleAnalyticsService.isLoggedIn(user)) {
      return user.activeB2bUnit.nonContractUnit ? 'kertatilaaja' : 'sopimustilaaja';
    }
    return 'anonymous';
  }

  private sendPageViewEvent(event: NavigationEnd) {
    this.pushTag(
      {
        'event': 'pageview',
        'page': {
          'path': event.urlAfterRedirects,
          'title': this.document.title
        }
      });
  }

  /**
   * Template for checkout step
   */
  checkoutStepEvent(cart: ValioCart) {
    const cartEntries: ValioCartEntry[] =
      [].concat.apply([], cart.dateGroupedEntries.filter(wrapper => wrapper.deliveryDate === cart.requestedDate).map(value => {
          return [].concat.apply([], value.entries.map(entryWrapper => entryWrapper.entries))
        }));
    this.pushTag({
      'event': 'ec:checkout',
      'ecommerce': {
        'currencyCode': cart.totalPriceWithoutTax?.currencyIso,
        'checkout': {
          'actionField': {
            'step': 1 //'#step_number#'				// integer
          },
          'products': cartEntries
            .filter(cartEntry => cartEntry.statusDisplay === 'open')
            .map(cartEntry => this.createCheckoutProduct(cartEntry))

        }
      }
    }
    );
  }

  private createCheckoutProduct(cartEntry: ValioCartEntry) {
    const product: ValioProduct = cartEntry.product;
    return {
      'id': ValioGoogleAnalyticsService.getId(product),
      'name': ValioGoogleAnalyticsService.getName(product),
      'category': ValioGoogleAnalyticsService.getCategory(product),
      'price': ValioGoogleAnalyticsService.getPriceFromOrderEntry(cartEntry),
      'variant': ValioGoogleAnalyticsService.getVariant(product),
      'brand': ValioGoogleAnalyticsService.getBrand(product),
      'quantity': cartEntry.quantity,
      'dimension1': ValioGoogleAnalyticsService.getDimension1(product),
      'dimension2': ValioGoogleAnalyticsService.getDimension2(product),
      'dimension3': ValioGoogleAnalyticsService.getDimension3(product),
      'dimension4': ValioGoogleAnalyticsService.getDimension4(product),
      'dimension5': ValioGoogleAnalyticsService.getDimension5(product),
      'dimension7': ValioGoogleAnalyticsService.getDimension7(this.user),
      'dimension14': ValioGoogleAnalyticsService.getDimension14(product),
      'dimension15': ValioGoogleAnalyticsService.getDimension15(product)
    }
  }

  /**
   * Template for purchase step
   */
  purchaseEvent(order: Order): void {
    const valioOrder: ValioOrder = <ValioOrder>order;
    if (this.areAllEntriesRemoved(valioOrder)) {
      this.fullRefund(valioOrder);
    } else if (this.allEntriesNew(valioOrder)) {
      this.pushPurchaseEvent(valioOrder);
    } else if (this.isQuantityIncremented(valioOrder)) {
      this.fullRefund(valioOrder);
      this.pushPurchaseEvent(valioOrder);
    } else if (this.isPartialRefund(valioOrder)) {
      this.partialRefund(valioOrder);
    } else {
      this.pushPurchaseEvent(valioOrder);
    }

  }

  private pushPurchaseEvent(valioOrder: ValioOrder): void {
    const orderId: string = this.createOrderId(valioOrder);
    const deliveryDate: Date = new Date(valioOrder.requestedDate);
    const destroy$ = new Subject<void>();

    this.orderTemplatesService.getBaseOrder().pipe(takeUntil(destroy$))
      .subscribe(baseOrder1 => {
        const baseOrderEntries: ValioBaseOrderEntry[] = baseOrder1?.entries ? ([] as ValioBaseOrderEntry[]).concat(...baseOrder1?.entries.map(weekdayEntry => weekdayEntry.entries)) : [];
        this.pushTag({
            'event': 'ec:purchase',
            'ecommerce': {
              'currencyCode': valioOrder.totalPriceWithoutTax?.currencyIso,
              'dimension6': ValioGoogleAnalyticsService.getDimension6FromUser(this.user),
              'dimension10': ValioGoogleAnalyticsService.getDimension10FromUser(this.user),
              'dimension11': this.getDimension11(this.user),
              'purchase': {
                'actionField': {
                  'id': orderId,			/* '#customer_number-based_user_number# + #delivery date# + #supplier code#' for example: '1234-20042020-1234'*/
                  'revenue': valioOrder.totalPriceWithoutTax.value, //'#order_revenue#',
                  'tax': valioOrder.totalTax.value, //'#order_tax#',
                  'shipping': valioOrder.freightCharge ? valioOrder.freightCharge.value : 0.0 //'#order_shipping#' shipping costs
                },
                'products': valioOrder.entries.map(orderEntry => this.createOrderProduct(orderEntry, deliveryDate, baseOrderEntries))
              }
            }

          }
        );
        destroy$.next()
      });
  }

  private allEntriesNew(valioOrder: ValioOrder): boolean {
    return valioOrder.entries.length != 0 && valioOrder.entries
      .map(entry => <ValioCartEntry>entry)
      .findIndex(entry => entry.originalQuantity != 0) < 0;
  }

  private isProductRefunded(orderEntry: ValioCartEntry): boolean {
    return orderEntry.quantity < orderEntry.originalQuantity;
  }

  private areAllEntriesRemoved(valioOrder: ValioOrder): boolean {
    return valioOrder.entries
      .map(entry => <ValioCartEntry>entry)
      .findIndex(entry => entry.quantity != 0) < 0;
  }

  private isQuantityIncremented(valioOrder: ValioOrder): boolean {
    return valioOrder.entries
      .map(entry => <ValioCartEntry>entry)
      .findIndex(entry => entry.originalQuantity != 0 && entry.quantity > entry.originalQuantity) >= 0;
  }

  private fullRefund(order: Order): void {
    this.pushTag({
      'event': 'ec:refund',
      'ecommerce': {
        'refund': {
          'actionField': {
            'id': this.createOrderId(order),
          }
        }
      }
    }
    );
  }

  private isPartialRefund(valioOrder: ValioOrder): boolean {
    return valioOrder.entries.length > 0 && valioOrder.entries.findIndex(entry => this.isProductRefunded(entry)) >= 0;
  }

  private partialRefund(order: ValioOrder): void {
    const refundedEntries: ValioCartEntry[] = order.entries.filter(entry => this.isProductRefunded(entry));
    if (refundedEntries.length > 0) {
      this.pushTag({
        'event': 'ec:partialRefund',
        'ecommerce': {
          'refund': {
            'actionField': {
              'id': this.createOrderId(order),
            }
          },
          'products': refundedEntries.map(entry => this.toPartialRefund(entry))
        }
      }
      );
    }
  }

  private toPartialRefund(entry: ValioCartEntry): object {
    return {
      'id': ValioGoogleAnalyticsService.getId(entry.product),
      'quantity': entry.originalQuantity - entry.quantity
    };
  }


  private createOrderId(order: Order): string {
    const valioOrder: ValioOrder = <ValioOrder>order;
    const deliveryDate: Date = new Date(valioOrder.requestedDate);
    return this.getDimension11(this.user) + '-' + this.cxDate.transform(deliveryDate, 'ddMMyyyy');
  }

  private createOrderProduct(orderEntry: OrderEntry, deliveryDate: Date, baseOrderEntries: ValioBaseOrderEntry[]) {
    const product: ValioProduct = <ValioProduct>orderEntry.product;

    const standingOrder: string = baseOrderEntries
      .map(baseOrder => baseOrder.product)
      .map(product => product.code)
      .find(code => code === product.code) ? 'kestotilaus' : 'ei-kestotilaus';
    return {
      'name': ValioGoogleAnalyticsService.getName(product),
      'id': ValioGoogleAnalyticsService.getId(product),
      'price': ValioGoogleAnalyticsService.getPriceFromOrderEntry(orderEntry),
      'brand': ValioGoogleAnalyticsService.getBrand(product),
      'category': ValioGoogleAnalyticsService.getCategory(product),
      'list': ValioGoogleAnalyticsService.getList(product), //'#list_name#',
      'position': ValioGoogleAnalyticsService.getPosition(product), //'#list_position#',
      'quantity': orderEntry?.quantity,
      'dimension1': ValioGoogleAnalyticsService.getDimension1(product), // '#supplier_code#',
      'dimension2': ValioGoogleAnalyticsService.getDimension2(product),
      'dimension3': ValioGoogleAnalyticsService.getDimension3(product),
      'dimension4': ValioGoogleAnalyticsService.getDimension4(product),
      'dimension5': ValioGoogleAnalyticsService.getDimension5(product),
      'dimension7': ValioGoogleAnalyticsService.getCustomerType(this.user),
      'dimension8': standingOrder,  //'#standing_ordered_product#','kestotilaus' if product is from template and marked as standing order. Otherwise 'ei-kestotilaus'
      'dimension9': this.cxDate.transform(deliveryDate, 'yyyyMMdd'), // '#delivery_date#',	 // format: 'yyyymmdd'
      'dimension14': ValioGoogleAnalyticsService.getDimension14(product),
      'dimension15': ValioGoogleAnalyticsService.getDimension15(product)
    }
  }

  hashValue(value: string) {
    if (value) {
      return new Md5().appendStr(value).end();
    }
    return value;
  }

  pageRefreshEvent(event: any): void {
    if (event instanceof NavigationEnd) {
      this.sendPageViewEvent(event);
    }
  }

  private isLoginEventRequired(user1: ValioUser): boolean {
    return this.user &&
      user1.uid !== this.user.uid &&
      ValioGoogleAnalyticsService.isLoggedIn(user1) &&
      ValioGoogleAnalyticsService.isAnonymous(this.user);
  }

  public static isLoggedIn(user: ValioUser): boolean {
    return user && user.uid !== OCC_USER_ID_ANONYMOUS;
  }

  public static isAnonymous(user: ValioUser): boolean {
    return user && user.uid === OCC_USER_ID_ANONYMOUS;
  }

  /**
   * product_name
   */
  private static getName(product: ValioProduct): string {
    return product.name;
  }

  /**
   * product_sku
   */
  private static getId(product: ValioProduct): string {
    return product.code;
  }

  private static getBrand(product: ValioProduct): string {
    return product.brands && product.brands.length > 0 ? product.brands[0].name : undefined;
  }

  /**
   * product_unit_price
   */
  private static getPrice(product: ValioProduct): number {
    return product.price ? product.price.value : undefined
  }

  private static getCurrencyIso(product: ValioProduct): string {
    return product?.price?.currencyIso;
  }

  private static getPriceFromOrderEntry(entry: OrderEntry): number {
    return entry.basePrice ? entry.basePrice.value : undefined;
  }

  /**
   * supplier_code
   */
  private static getDimension1(product: ValioProduct): string {
    return product.partnerSupplier ? product.partnerSupplier.code : undefined
  }

  /**
   * ean
   */
  private static getDimension2(product: ValioProduct): string {
    return product.ean;
  }

  /**
   * Super category of product
   */
  private static getDimension3(product: ValioProduct): string {
    return ValioGoogleAnalyticsService.getCategoryLevel(product, 0);
  }

  /**
   * Super category of dimension3
   */
  private static getDimension4(product: ValioProduct): string {
    return ValioGoogleAnalyticsService.getCategoryLevel(product, 1);
  }

  /**
   * Super category of dimension4
   */
  private static getDimension5(product: ValioProduct): string {
    return ValioGoogleAnalyticsService.getCategoryLevel(product, 2);
  }

  /**
   * Customer type
   */
  public static getDimension6FromUser(user: ValioUser): string {
    if (ValioGoogleAnalyticsService.isLoggedIn(user) && user.activeB2bUnit) {
      return ValioGoogleAnalyticsService.getDimension6FromB2bUnit(user.activeB2bUnit);
    }
    return 'anonymous'
  }

  /**
   * Customer type
   */
  private static getDimension6FromB2bUnit(b2bUnit: B2BUnit): string {
    if (b2bUnit) {
      return b2bUnit.nonContractUnit ? 'kertatilaaja' : 'sopimustilaaja';
    }
    return 'anonymous';
  }

  /**
   * Customer type string
   */
  private static getDimension7(user: ValioUser): string {
    if (ValioGoogleAnalyticsService.isLoggedIn(user) && user.activeB2bUnit) {
      return user.activeB2bUnit.nonContractUnit ? 'kertatilaaja' : 'sopimustilaaja';
    }
    return 'anonymous';
  }

  /**
   * customer number
   */
  private static getDimension10FromUser(user: ValioUser): string {
    if (user && user.activeB2bUnit && ValioGoogleAnalyticsService.isLoggedIn(user)) {
      return ValioGoogleAnalyticsService.getDimension10FromB2bUnit(user.activeB2bUnit);
    }
    return undefined;
  }

  /**
   * customer number
   */
  private static getDimension10FromB2bUnit(b2bUnit: B2BUnit): string {
    return b2bUnit ? b2bUnit.uid : undefined;
  }


  /**
   * uid hash
   */
  private getDimension11(user1: ValioUser) {
    if (user1) {
      return this.hashValue(user1.uid)
    }
    return undefined;
  }

  /**
   * selected_supplier_from_top_navigation
   */
  private static getDimension13(vendor: Vendor): string {
    return vendor && vendor.code !== 'all' ? vendor.code : undefined;  //'#selected_supplier_from_top_navigation#',;
  }

  /**
   * product_labels_and_tags
   */
  private static getDimension14(product: ValioProduct): string {
    let labels: string = '';
    if (product.campaignProduct) {
      labels = 'tarjous'
    }
    if (product.newProduct) {
      if (labels.length > 0) {
        labels.concat(',');
      }
      labels.concat('uutuus');
    }
    return labels.length > 0 ? labels : undefined;
  }

  /**
   * Availability
   */
  private static getDimension15(product: ValioProduct): string {
    const hasStock: boolean = product.stock && product.stock.stockLevel > 0
    return product.purchasable ? 'tilattavissa' : product.closingTimePassed && hasStock ? 'tilattavissa – ei valitulle päivälle' : 'ei tilattavissa';
  }

  /**
   * List price
   */
  private static getVariant(product: ValioProduct): number {
    // Use list price if available, fallback to unit price
    return product.listPrice ? product.listPrice.value : ValioGoogleAnalyticsService.getPrice(product);
  }

  /**
   * position in list
   */
  private static getPosition(product: ValioProduct): number {
    return product && product.gaList ? product.gaList.position : undefined;
  }

  /**
   * list name
   */
  private static getList(product: ValioProduct): string {
    return product && product.gaList ? product.gaList.listName : undefined;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
