import {NgModule} from '@angular/core';
import {
  ContextServiceMap,
  ExternalRoutesModule,
  SITE_CONTEXT_FEATURE,
  SiteContextModule,
  SiteContextParamsService
} from "@spartacus/core";
import {valioServiceMapFactory} from "./valio-context-service-map";
import {ValioSiteContextParamsService} from "./valio-site-context-params.service";
import {reducerProvider, reducerToken} from "./valio-partner-site.reducer";
import {EffectsModule} from "@ngrx/effects";
import {ValioPartnerSiteEffects} from "./valio-partner-site.effect";
import {StoreModule} from "@ngrx/store";
import {ValioRestrictedAssortmentEffects} from "./valio-restricted-assortment.effect";

@NgModule({
  imports: [
    SiteContextModule.forRoot(),
    ExternalRoutesModule.forRoot(),
    StoreModule.forFeature(SITE_CONTEXT_FEATURE, reducerToken),
    EffectsModule.forFeature([ValioPartnerSiteEffects, ValioRestrictedAssortmentEffects])
  ],
  providers: [
    {
      provide: ContextServiceMap,
      useFactory: valioServiceMapFactory,
    },
    {
      provide: SiteContextParamsService,
      useClass: ValioSiteContextParamsService
    },
    reducerProvider
  ]
})
export class ValioSiteContextModule {
  constructor() {
  }
}
