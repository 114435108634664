import {Injectable} from '@angular/core';

import {RoutingService} from '@spartacus/core';
import {Observable} from 'rxjs';
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class ValioCreditPageGuard  {

  constructor(
    protected routingService: RoutingService,
    protected cartService: ValioCartService,
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.cartService.getActive().pipe(map(cart => {
      if (cart.paymentModeGroupedEntries) {
        if (cart.paymentModeGroupedEntries['INVOICE'] != null && cart.paymentModeGroupedEntries['INVOICE'].hasUnapprovedLines) {
          this.routingService.go('/checkout/review-order-invoice');
          return false;
        }
        if (cart.deliveryAddress.phone == null || cart.deliveryAddress.email == null || (cart.deliverySlot == null && cart.allowedPickupPoints?.find(pp => pp.selected) == null)) {
          this.routingService.go('/checkout/shipping-address');
          return false;
        }
        return true;
      }
      return false;
    }));
  }
}
