import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {CmsComponentData, ProductCarouselComponent} from '@spartacus/storefront';
import {Observable, of} from 'rxjs';
import {ValioCmsBannerComponent, ValioCmsProductCarouselComponent} from '../../../../../models';
import {filter, map, switchMap} from 'rxjs/operators';
import {ValioUserService} from '../../../../../services/user/valio-user.service';
import {CmsService, Product, ProductService} from '@spartacus/core';
import {ValioProductService} from '../../../../../services/product/valio-product.service';
import {ValioPartnerSiteService} from '../../../../../services/site/valio-partner-site.service';
import {ALL_PARTNERS} from '../../../../../services/site/valio-site.constants';
import {ValioCartService} from '../../../../../services/cart/valio-cart.service';
import {ValioCarouselService} from '../../../../shared/components/carousel/valio-carousel.service';

@Component({
  selector: 'valio-cx-product-carousel',
  templateUrl: './valio-product-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioProductCarouselComponent extends ProductCarouselComponent implements OnInit, OnDestroy {
  fullWidth$: Observable<boolean>;
  hasUICart$: Observable<boolean>;
  isAnonymous$: Observable<boolean>;
  data$ : Observable<ValioCmsProductCarouselComponent>
  selectedPartner$: Observable<string>;
  products$: Observable<Product[]>;
  bannerComponent$: Observable<ValioCmsBannerComponent>;
  private valioComponentData$: Observable<ValioCmsProductCarouselComponent>;




  constructor(
    protected valioComponentData: CmsComponentData<ValioCmsProductCarouselComponent>,
    protected productService: ProductService,
    protected userService: ValioUserService,
    protected cmsService: CmsService,
    protected cdr: ChangeDetectorRef,
    protected partnerSiteService: ValioPartnerSiteService,
    protected cartService: ValioCartService,
    protected el: ElementRef,
    protected carouselService: ValioCarouselService
  ) {
    super(valioComponentData, productService);
  }

  ngOnInit(): void {
    this.selectedPartner$ = this.partnerSiteService.getActive();
    this.fullWidth$ = this.cartService.hasUiCart(true);
    this.hasUICart$ = this.cartService.hasUiCart();
    this.isAnonymous$ = this.userService.isAnonymous();
    this.data$ = this.valioComponentData.data$
    this.valioComponentData$ = this.valioComponentData.data$.pipe(
      filter(Boolean)
    );
    this.bannerComponent$ = this.valioComponentData$.pipe(
      switchMap(data => {
          return data.bannerComponent ? this.cmsService.getComponentData<ValioCmsBannerComponent>(data.bannerComponent.uid) : of({});
        }
      )
    );

    this.valioComponentData$.subscribe(data =>
      this.products$ = data.productCodes ? (this.productService as ValioProductService)
          .getMany(data.productCodes.trim().split(' ')).pipe(
          map(products => {
            return products;
          }))
        : of([])
    ).unsubscribe();
  }

  ngOnDestroy() {

  }

  showCarousel(selectedPartner: string, partner: string) {
    return (selectedPartner == ALL_PARTNERS && (partner == null || partner.length == undefined)) || partner == selectedPartner;
  }

  log(data: ValioCmsProductCarouselComponent) {
    console.log(data);
  }
}
