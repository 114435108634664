import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoggerService, normalizeHttpError, tryNormalizeHttpError} from '@spartacus/core';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {CheckoutActions} from '../actions';
import {CartActions} from "@spartacus/cart/base/core";
import {ScheduledReplenishmentOrderConnector} from "@spartacus/order/core";


@Injectable()
export class ReplenishmentOrderEffects {

  private scheduleReplenishmentOrder$: Observable<
    | CheckoutActions.ScheduleReplenishmentOrderSuccess
    | CheckoutActions.ScheduleReplenishmentOrderFail
    | CartActions.RemoveCart
  >

  constructor(
    private actions$: Actions,
    private checkoutReplOrderConnector: ScheduledReplenishmentOrderConnector,
    private loggerService: LoggerService
  ) {
    this.scheduleReplenishmentOrder$= createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.SCHEDULE_REPLENISHMENT_ORDER),
        map((action: CheckoutActions.ScheduleReplenishmentOrder) => action.payload),
        mergeMap((payload) => {
          return this.checkoutReplOrderConnector
            .scheduleReplenishmentOrder(
              payload.cartId,
              payload.scheduleReplenishmentForm,
              payload.termsChecked,
              payload.userId
            )
            .pipe(
              switchMap((data) => [
                new CartActions.RemoveCart({cartId: payload.cartId}),
                new CheckoutActions.ScheduleReplenishmentOrderSuccess(data),
              ]),
              catchError((error) =>
                of(
                  new CheckoutActions.ScheduleReplenishmentOrderFail(
                    tryNormalizeHttpError(error, this.loggerService)
                  )
                )
              )
            );
        })
      )
    );
  }



}
