import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, concatMap, map} from "rxjs/operators";
import {makeErrorSerializable} from "../util/valio-serialization-utils";
import {ValioEnvironmentConnector} from "./valio-environment.connector";
import {
  LOAD_ANALYTICS,
  LoadAnalyticsFail,
  LoadAnalyticsSuccess,
  ValioEnvironmentAction
} from "./valio-environment.action";

@Injectable()
export class ValioEnvironmentEffects {


  private loadAnalytics$: Observable<ValioEnvironmentAction>

  constructor(
    private actions$: Actions,
    private environmentConnector: ValioEnvironmentConnector
  ) {

    this.loadAnalytics$ = createEffect(() => this.actions$.pipe(
        ofType(LOAD_ANALYTICS),
        concatMap(() => {
          return this.environmentConnector.getAnalyticsConfig().pipe(
            map(analyticsConfig => {
              return new LoadAnalyticsSuccess(analyticsConfig);
            }),
            catchError(error =>
              of(new LoadAnalyticsFail(makeErrorSerializable(error)))
            )
          );
        })
      )
    );
  }
}
