import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {OccConfig, OccEndpointsService} from "@spartacus/core";
import {Observable} from "rxjs";
import {AnalyticsConfig} from "./valio-environment.objects";

@Injectable({
  providedIn: 'root',
})
export class ValioOccEnvironmentAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected config: OccConfig,
  ) {
  }

  getAnalyticsConfig(): Observable<AnalyticsConfig> {
    return this.http.get<AnalyticsConfig>(this.occEndpoints.buildUrl('analyticsConfig'));
  }
}
