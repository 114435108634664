import {Component, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsVideoComponent} from '../../../../models';
import {Observable} from "rxjs";

@Component({
  selector: 'valio-video-component',
  templateUrl: './valio-video.component.html',
})
export class ValioVideoComponent implements OnInit {
  data$: Observable<ValioCmsVideoComponent>;

  constructor(
    protected componentData: CmsComponentData<ValioCmsVideoComponent>
  ) {
  }

  ngOnInit(): void {
    this.data$ = this.componentData.data$;
  }

}
