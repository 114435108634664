import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsThreeBoxBannerComponent} from '../../../../models';
import {Observable} from "rxjs";


@Component({
  selector: 'valio-cx-three-box-banner',
  templateUrl: './valio-three-box-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioThreeBoxBannerComponent implements OnInit {

  data$:Observable<ValioCmsThreeBoxBannerComponent>;

  constructor(
    protected componentData: CmsComponentData<ValioCmsThreeBoxBannerComponent>,
  ) {
  }

  ngOnInit(): void {
    this.data$ = this.componentData.data$;
  }


}
