import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ProductSearchAdapter, ProductSearchConnector, ProductSearchPage} from "@spartacus/core";
import {ValioOccProductSearchAdapter} from "./valio-occ-product-search.adapter";
import {CategoryHierarchy} from "../../models";

@Injectable({
  providedIn: 'root',
})
export class ValioProductSearchConnector extends ProductSearchConnector {

  constructor(protected productSearchAdapter: ValioOccProductSearchAdapter) {
    super(productSearchAdapter);
  }

  loadLastPurchasedProducts(): Observable<ProductSearchPage> {
    return (this.productSearchAdapter as ValioOccProductSearchAdapter).loadLastPurchasedProducts();
  }

  loadTopSellingProducts(): Observable<ProductSearchPage> {
    return (this.productSearchAdapter as ValioOccProductSearchAdapter).loadTopSellingProducts()
  }

  loadBoughtTogetherProducts(productCode:string): Observable<ProductSearchPage> {
    return (this.productSearchAdapter as ValioOccProductSearchAdapter).loadBoughtTogetherProducts(productCode);
  }

  getCategories(superCategory: string, partner: string, assortment: string, b2bunit: string, lang: string): Observable<CategoryHierarchy> {
    return (this.productSearchAdapter as ValioOccProductSearchAdapter).getCategories(superCategory, partner, assortment, b2bunit, lang);
  }

}
