import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from "rxjs";
import {catchError, concatMap, filter, map} from "rxjs/operators";
import {ValioRoutecalendarConnector} from "./valio-routecalendar.connector";
import {
  LOAD_ROUTE_CALENDAR,
  ValioLoadRouteCalendar,
  ValioLoadRouteCalendarFail,
  ValioLoadRouteCalendarSuccess
} from "./valio-routecalendar.action";

@Injectable()
export class ValioRouteCalendarEffects {
  private loadRouteCalendar$: Observable<ValioLoadRouteCalendarSuccess | ValioLoadRouteCalendarFail>

  constructor(
    protected actions$: Actions,
    protected routeCalendarConnector: ValioRoutecalendarConnector,
  ) {
    this.loadRouteCalendar$ = createEffect(() => this.actions$.pipe(
        ofType(LOAD_ROUTE_CALENDAR),
        filter((action: ValioLoadRouteCalendar) => !!(action.cartId && action.userId)),
        map((action: ValioLoadRouteCalendar) => action),
        concatMap(action => {
            return this.routeCalendarConnector.getRouteCalendar(action.userId, action.cartId)
              .pipe(
                map(routeCal => new ValioLoadRouteCalendarSuccess(routeCal))
              );
          }
        ),
        catchError(error => {
            console.error("Error loading route calendar: " + error);
            return of(new ValioLoadRouteCalendarFail());
          }
        )
      )
    );
  }


}

