<div [ngClass]="{'closing-time-passed':product.closingTimePassed}" class="valio-cx-product-grid-item"
     [attr.data-productid]="product.code"
     [attr.data-listName]="listName ? listName : null">
  <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" class="cx-product-image-container">
    <div class="cx-product-image-margin">
      <cx-media class="cx-product-image"
                [container]="product.images?.PRIMARY"
                format="thumbnail"
                [alt]="product.summary"></cx-media>
    </div>
    <ng-container *ngIf="product.purchasable">
      <div class="product-campaign-banner campaign-product" *ngIf="product?.campaignProduct">
        {{ 'valioProduct.productDetails.campaignOffer' | cxTranslate }}
      </div>
      <div class="product-campaign-banner new-product" *ngIf="product?.newProduct">
        {{ 'valioProduct.productDetails.campaignNew' | cxTranslate }}
      </div>
    </ng-container>
    <valio-product-icons
      [product]="product"
    ></valio-product-icons>
  </a>
  <p class="cx-product-code">
    <ng-container *ngIf="partnerIcon$; else brandIcon">
      <span class="code" title="{{ product.partnerSupplier?.name }}" *ngIf="partnerIcon$|async as partnerIcon; else code">
        <cx-media [container]="partnerIcon.media" [alt]="partnerIcon.altText"></cx-media>
      </span>
    </ng-container>
    <ng-template #brandIcon>
      <ng-container *ngIf="product.partnerSupplier.useBrandImages && product.brands?.length; else productLogo">
        <ng-container *ngFor="let brand of product.brands; first as isFirst">
          <span class="code" title="{{ brand.name }}" *ngIf="isFirst && brand.image; else productLogo">
              <cx-media [container]="brand.image" [alt]="brand.name"></cx-media>
          </span>
        </ng-container>
      </ng-container>
    </ng-template>
    <ng-template #productLogo>
      <ng-container *ngIf="product.partnerSupplier?.productLogo; else code">
          <span class="code" title="{{ product.partnerSupplier?.name }}" *ngIf="product.partnerSupplier?.productLogo">
              <cx-media [container]="product.partnerSupplier?.productLogo" [alt]="product.partnerSupplier?.name"></cx-media>
          </span>
      </ng-container>
    </ng-template>
    | <span id="product_{{product.code}}">{{ 'cartItems.id' | cxTranslate }} {{ product.code |productCode}}</span>
    <ng-template #code>
      {{ product.partnerSupplier?.name }}
    </ng-template>
  </p>
  <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" class="cx-product-name" [innerHtml]="product.nameHtml"></a>

  <ng-container *ngIf="showDeliveryPeriod; else unit">
    <valio-product-delivery-period
      [product]="product"
    ></valio-product-delivery-period>
  </ng-container>

  <ng-template #unit>
    <div class="cx-product-unit">
      <p class="cx-product-unit-row">
        <span *ngIf="product.unit?.conversionToInvoiceUnit">{{product.unit?.conversionToInvoiceUnit}}&nbsp;&nbsp;&nbsp;</span>
        <span *ngIf="product.comparisonPrice">{{product.comparisonPrice?.formattedValue}}/{{product.baseUnit?.name}}</span>
      </p>
      <p class="cx-product-unit-row" *ngIf="product.salesUnitPrice">
        <span>{{product.salesUnitPrice?.formattedValue}}/{{product.unit?.name}}</span>
      </p>
    </div>
  </ng-template>

  <valio-cx-product-price [product]="product"></valio-cx-product-price>
  <valio-cx-add-to-cart [setProduct]="product"></valio-cx-add-to-cart>
</div>
