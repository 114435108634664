import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {Config, I18nModule, provideDefaultConfig} from '@spartacus/core';
import {LogoutGuard, MediaModule, OutletModule, PageLayoutComponent} from '@spartacus/storefront';

import {ValioCommonModule} from "../../../../shared/valio-common.module";
import {ValioPipeModule} from '../../../../services/pipes/valio-pipe.module';
import {ValioLogoutModalComponent} from "./valio-logout-modal.component";
import {RouterModule} from "@angular/router";
import {ValioLogoutPreviewGuard} from "./valio-logout-preview.guard";
import {ValioLogoutGuard} from "./valio-logout-guard";
import {valioLogoutModalLayoutConfig} from "./valio-logout-modal-layout.config";


@NgModule({
    providers: [{
            provide: LogoutGuard,
            useClass: ValioLogoutGuard
        },
      provideDefaultConfig(valioLogoutModalLayoutConfig as Config)
    ],

    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        ValioCommonModule,
        MediaModule,
        RouterModule.forChild([
            {
                path: null,
                canActivate: [ValioLogoutPreviewGuard],
                component: PageLayoutComponent,
                data: { cxRoute: 'logoutPreview' },
            },
        ]),
        ValioPipeModule,
    ],
    declarations: [ValioLogoutModalComponent],
    exports: [ValioLogoutModalComponent]
})
export class ValioLogoutModalModule {
}
