import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RoutingService} from '@spartacus/core';
import {Actions} from '@ngrx/effects';
import {Subscription} from "rxjs";
import {ForgotPasswordComponent} from "@spartacus/user/profile/components";
import {openCloseSpinner} from "../../../../services/util/valio-modals-utils";
import {ValioForgotPasswordComponentService} from "./valio-forgot-password.component.service";
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'valio-cx-forgot-password',
  templateUrl: './valio-forgot-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ValioForgotPasswordComponent extends ForgotPasswordComponent implements OnInit, OnDestroy {
  submited = false;
  emailAddress: string;
  errorMessage: string;

  subscriptions: Subscription = new Subscription();
  //form: UntypedFormGroup;

  constructor(
    protected fb: UntypedFormBuilder,
    protected userService: ValioUserService,
    protected routingService: RoutingService,
    protected actions$: Actions,
    protected cdr: ChangeDetectorRef,
    protected service: ValioForgotPasswordComponentService,
    @Inject(DOCUMENT) protected document: Document
  ) {
    super(service);
  }

  ngOnInit() {
    this.form = this.service.form;
    this.subscriptions.add(this.isUpdating$.subscribe(value => {
      openCloseSpinner(this.document,value);
    }));
    this.subscriptions.add(this.service.isSuccess$.subscribe(value => {
      openCloseSpinner(this.document,false);
    }));
    this.subscriptions.add(this.service.isFail$.subscribe(value => {
      openCloseSpinner(this.document,false);
      this.errorMessage = 'valioRegister.inValidRegister.reason';
      this.form.controls['userEmail'].setErrors({'incorrect': true});
      this.cdr.detectChanges();
    }));
  }

  onSubmit() {
    super.onSubmit();
    this.requestForgotPasswordEmail()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  requestForgotPasswordEmail() {
    this.submited = true;
    if (this.form.invalid) {
      return;
    }
    this.service.requestEmail();
  }

  toFrontPage(): void {
    this.emailAddress = null;
    this.routingService.go({cxRoute: 'login'});
  }

  validateForm() {
    this.errorMessage = 'forgottenPassword.enterValidUid';
    this.form.controls['userEmail'].updateValueAndValidity();
  }


  isUserIDValid(): boolean {
    return this.form.controls['userEmail'].invalid && (this.submited || (this.form.controls['userEmail'].touched && this.form.controls['userEmail'].dirty));
  }

}
