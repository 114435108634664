import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ValioUser} from "../../../../models/misc.model";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {ValioRegisterExistingComponent} from "../../user/register/valio-register-existing.component";
import {UpdateProfileComponent} from "@spartacus/user/profile/components";
import {Subscription} from "rxjs";
import {ValioUpdateProfileComponentService} from "./valio-update-profile.component.service";
import {UserAccountFacade} from "@spartacus/user/account/root";

@Component({
  selector: 'valio-cx-update-profile-form',
  templateUrl: './valio-update-profile-form.component.html',
})
export class ValioUpdateProfileFormComponent extends UpdateProfileComponent implements OnInit, OnDestroy{
  @Input()
  user: ValioUser;

  @Output()
  submited = new EventEmitter<{ userUpdates: ValioUser }>();

  @Output()
  cancelled = new EventEmitter<void>();
  subscriptions: Subscription = new Subscription();
  submitClicked = false;

  private originalFormValue: any;
  constructor(protected service: ValioUpdateProfileComponentService,
              protected fb: UntypedFormBuilder,
              protected userAccountService: UserAccountFacade,
              protected cdr: ChangeDetectorRef,) {
    super(service);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.email],
      phone: ['', ValioRegisterExistingComponent.phoneNumberValidator],
      enableOrderConfirmationEmails: []
    });
    this.subscriptions.add(
      this.userAccountService.get().subscribe((user) => {
        if (user) {
          this.form = this.service.initForm(user);
          this.originalFormValue = JSON.stringify(this.form.value);
          this.cdr.detectChanges();
        }
      }),
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  onSubmit(): void {
    this.submitClicked = true;
    if (this.form.invalid) {
      return;
    }
    super.onSubmit();
    this.submited.emit({

      userUpdates: {...this.form.value},
    });
  }

  onCancel(): void {
    this.cancelled.emit();
  }

}
