import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Actions} from "@ngrx/effects";
import {CartActions} from "@spartacus/cart/base/core";
import {DeliveryMode} from "@spartacus/cart/base/root";
import {Country, OCC_USER_ID_ANONYMOUS, RoutingService, UserIdService} from '@spartacus/core';
import {CustomFormValidators} from '@spartacus/storefront';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {Observable, Subscription, Subject} from 'rxjs';
import {filter, map, withLatestFrom, takeUntil} from 'rxjs/operators';
import {ValioUser} from '../../../../../../models/misc.model';
import {RouteCalendar} from "../../../../../../models/valio-calendar.model";
import {ValioAddress} from "../../../../../../services/address/address-model";
import {ValioCart, ValioPointOfService} from '../../../../../../services/cart/valio-cart.objects';
import {ValioCartService} from '../../../../../../services/cart/valio-cart.service';
import {ValioRouteCalendarService} from "../../../../../../services/routecalendar/valio-routecalendar.service";
import {AnonymousPostalCodeValidator} from "../../../../../../services/user/anonymousPostalCode.validator";
import {ValioUserService} from '../../../../../../services/user/valio-user.service';
import {openCloseSpinner} from "../../../../../../services/util/valio-modals-utils";
import VatIdValidator from "../../../../../../shared/utils/validators/VatIdValidator";
import {ValioRegisterExistingComponent} from "../../../../user/register/valio-register-existing.component";
import {DOCUMENT} from "@angular/common";
import {Action} from "@ngrx/store";

@Component({
  selector: 'valio-cx-shipping-address-details',
  templateUrl: './valio-shipping-address-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioShippingAddressDetailsComponent implements OnInit, OnDestroy {

  @Input() cart: ValioCart;
  @Input() editable = true as boolean;
  @Input() headerDate: Date;
  user$: Observable<ValioUser>;
  contactForm: UntypedFormGroup;
  loggedUser$: Observable<LoggedUser>;
  routeCalendar$: Observable<RouteCalendar>;

  deliveryslotNotSelected: boolean;
  pickupPointNotSelected: boolean;

  soldToAddressForm: UntypedFormGroup;
  shipToAddressForm: UntypedFormGroup;
  useShipTo = false;
  submitted = false;
  requestedDate: Date;

  selectedPickupValue: string;
  selectedPickupPoint: ValioPointOfService;
  subscription: Subscription = new Subscription();
  userId: string;


  constructor(
    protected fb: UntypedFormBuilder,
    protected routingService: RoutingService,
    protected cartService: ValioCartService,
    protected userIdService: UserIdService,
    protected cdr: ChangeDetectorRef,
    protected userService: ValioUserService,
    protected userAccountFacade: UserAccountFacade,
    protected anonymousPostalCodeValidator: AnonymousPostalCodeValidator,
    protected actions$: Actions,
    protected routeCalendarService: ValioRouteCalendarService,
    @Inject(DOCUMENT) protected document: Document) {
  }

  ngOnInit(): void {
    this.loggedUser$ = this.cartService.isGuestCart()
      .pipe(
        withLatestFrom(this.userService.isSignedIn()),
        map(([isGuestCart, isSigned]) =>
          new LoggedUser(isSigned && !isGuestCart)
        )
      );
    this.routeCalendar$ = this.routeCalendarService.getRouteCalendar().pipe(
      filter(cal => cal?.routeDates?.length > 0)
    );
    this.initContactForm(this.cart.deliveryAddress.phone, this.cart.deliveryAddress.email ? this.cart.deliveryAddress.email : '', this.cart.deliveryAddress.vatId);
    this.user$ = this.userAccountFacade.get().pipe(map(user => user as ValioUser))
    this.useShipTo = this.cart.paymentAddress?.postalCode != null && this.cart.paymentAddress?.postalCode != '';

    this.soldToAddressForm = this.initAddressForm(this.useShipTo ? this.cart.paymentAddress : this.cart.deliveryAddress, [Validators.required, CustomFormValidators.emailValidator], [Validators.required, VatIdValidator.valid]);
    this.shipToAddressForm = this.initAddressForm(this.useShipTo ? this.cart.deliveryAddress : {}, null, null);

    this.subscription.add(
      this.cartService.getRequestedDate().subscribe(d => this.requestedDate = d)
    );
    this.selectedPickupValue = this.cart.allowedPickupPoints?.find(pp => pp.selected)?.name;
    this.selectedPickupPoint = this.cart.allowedPickupPoints?.find(pp => pp.selected);
    this.subscription.add(this.userIdService.getUserId().subscribe((userId) => {
      this.userId = userId
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getDeliveryDay(): string {
    return 'cart.header.weekday.' + (1 + this.headerDate.getDay());
  }

  goNext(cart: ValioCart, logged: boolean): void {
    this.submitted = true;
    if (this.cart.deliverySlot == null && !this.cart.allowedPickupPoints) {
      this.deliveryslotNotSelected = true;
    } else {
      this.deliveryslotNotSelected = false;
      if (!logged) {
        this.pickupPointNotSelected = this.cart.allowedPickupPoints && !this.selectedPickupValue;
        if (this.allowGoNext()) {
          if (cart.user.uid === OCC_USER_ID_ANONYMOUS) {
            openCloseSpinner(this.document, true);
            this.updateAddresses()
            const destroy$ = new Subject<void>();
            this.actions$
              .pipe(takeUntil(destroy$))
              .subscribe(action => {
                if (action.type === CartActions.LOAD_CART_SUCCESS) {
                  // Join email, first name and last name here, do splitting in backend
                  const email: string = this.soldToAddressForm.controls.email.value + '|' + this.soldToAddressForm.controls.firstName.value + '|' + this.soldToAddressForm.controls.lastName.value;
                  this.handleAnonymousCheckout(cart, email, this.userId);
                  this.closeSubject(destroy$)
                }
              })
          } else {
            this.anonymousCheckout();
          }
        }
      } else {
        if (this.contactForm.valid) {
          this.routingService.go('/checkout/review-order-invoice');
        }
      }
    }
    this.cdr.detectChanges();
  }
  private closeSubject(subject:Subject<void>):void {
      subject.next();
      subject.complete();
  }

  private anonymousCheckout() {
    this.routingService.go('/checkout/review-order-credit');
  }

  back(): void {
    this.routingService.go('cart');
  }

  updateDeliverySlot(val: string): void {
    this.cartService.updateHeader('deliverySlot', val);
    this.setAddressUpdateRan()
  }

  updatePhone(): void {
    if (this.contactForm.controls.phone.valid) {
      this.cartService.updateHeader('phone', this.contactForm.controls.phone.value);
    }
  }

  updateEmail(): void {
    if (this.contactForm.controls.email.valid) {
      this.cartService.updateHeader('email', this.contactForm.controls.email.value);
    }
  }

  updateVatId(): void {
    if (this.contactForm.controls.vatId.valid) {
      this.cartService.updateHeader('vatId', this.contactForm.controls.vatId.value);
    }
  }

  addressUpdateRunning = false;

  updateAddresses(): void {
    this.addressUpdateRunning = true;
    this.updateDeliveryAddress();
    const destroy$ = new Subject<void>();
    // This needs to be done like this. Otherwise, won't update soldTo/paymentAddress to cart when using shipTo. Most likely has something to do with requireLoadedCart method.
    this.actions$
      .pipe(takeUntil(destroy$))
      .subscribe(action => {
          if (action.type === CartActions.LOAD_CART_SUCCESS) {
            this.updatePaymentAddress();
            this.addressUpdateRunning = false;
            this.cdr.detectChanges();
            this.closeSubject(destroy$);
          }
        }
      );
  }

  updatePaymentAddress(): void {
    this.addressUpdateRunning = true;
    if (this.useShipTo) {
      this.cartService.changeSoldtoAddress(this.createAddressPayload(this.soldToAddressForm));
    } else {
      this.cartService.changeShiptoAddress(this.createAddressPayload(this.soldToAddressForm));
    }
    this.setAddressUpdateRan();
  }

  /**
   * This method is used to update the email for anonymous users when they are proceeding to checkout for the first time
   * @param cart cart
   * @param email email
   * @param userId userid
   * @private handleAnonymousCheckout
   */
  private handleAnonymousCheckout(cart: ValioCart, email: string, userId: string): void {
    const destroy$ = new Subject<void>();
    const destroy2$ = new Subject<void>();
    // Start listening for events before updating the email
    this.actions$.pipe(takeUntil(destroy$))
      .subscribe(action => {
          if (action.type === CartActions.ADD_EMAIL_TO_CART_SUCCESS) {
            this.actions$.pipe(takeUntil(destroy2$)).subscribe(action2 => {
              if (action2.type === CartActions.LOAD_CART_SUCCESS || action2.type === CartActions.MERGE_CART_SUCCESS ) {
                // Sometimes MERGE_CART_SUCCESS is pushed to events instead of LOAD_CART_SUCCESS. This has something to do with how carts are handled internally.
                this.anonymousCheckout();
                this.closeSubject(destroy2$);
              }
            });
            this.closeSubject(destroy$);
          }
          if (action.type === CartActions.ADD_EMAIL_TO_CART_FAIL ) {
            console.log("failed to register " + action.type)
            openCloseSpinner(this.document, false);
            this.closeSubject(destroy$);
          }
        }
      )

    this.cartService.addValioEmail(cart, email, userId);

  }


  updateDeliveryAddress(): void {
    this.addressUpdateRunning = true;
    if (this.useShipTo) {
      this.cartService.changeShiptoAddress(this.createAddressPayload(this.shipToAddressForm));
    } else {
      this.cartService.changeSoldtoAddress({});
    }
    this.setAddressUpdateRan();
  }

  setAddressUpdateRan(): void {
    const destroy$ = new Subject<void>();
    this.actions$
      .pipe(takeUntil(destroy$))
      .subscribe((action: Action) => {
          if (action.type === CartActions.LOAD_CART_SUCCESS) {
            this.addressUpdateRunning = false;
            this.cdr.detectChanges();
            this.closeSubject(destroy$);
          }
        }
      );
  }

  createAddressPayload(form: UntypedFormGroup) {
    return {
      ...form.getRawValue(),
      country: {isocode: 'FI'} as Country
    } as ValioAddress;
  }

  private initContactForm(phone: string, email: string, vatId: string) {
    this.contactForm = this.fb.group({
      phone: [phone, ValioRegisterExistingComponent.phoneNumberValidator],
      email: [email, CustomFormValidators.emailValidator],
      vatId: [vatId, [Validators.required, VatIdValidator.valid]]
    });
  }

  private initAddressForm(address: ValioAddress, emailValidators, vatIdValidators): UntypedFormGroup {
    return this.fb.group(
      {
        vatId: [address.vatId ? address.vatId : '', vatIdValidators],
        firstName: [address.firstName ? address.firstName : '', Validators.required],
        lastName: [address.lastName ? address.lastName : '', Validators.required],
        phone: [address.phone ? address.phone : '+358', ValioRegisterExistingComponent.phoneNumberValidator],
        email: [address.email ? address.email : '', emailValidators],
        companyName: [address.companyName ? address.companyName : '', Validators.required],
        department: [address.department ? address.department : ''],
        line1: [address.line1 ? address.line1 : '', Validators.required],
        postalCode: [address.postalCode ? address.postalCode : '', {
          validators: Validators.required,
          asyncValidators: this.anonymousPostalCodeValidator.validate.bind(this.anonymousPostalCodeValidator)
        }],
        town: [address.town ? address.town : '', Validators.required]
      }
    );
  }

  selectDate(date: string) {
    this.cartService.changeDate(date);
  }

  updateDeliveryMode(mode: DeliveryMode) {
    this.cartService.changeDeliveryMode(mode);
    if(mode.code !== this.cart.deliveryMode.code) {
      this.useShipTo = false;
      this.updateAddresses();
    }
  }

  updatePickupLocation(point: ValioPointOfService) {
    if(point?.name != null) {
      this.cartService.changePickupLocation(point.name);
      this.pickupPointNotSelected = false;
    }else {
      this.cartService.changePickupLocation(null);
      this.pickupPointNotSelected = true;
    }
    this.updateAddresses();
  }

  setUseShipTo(checked: boolean) {
    this.useShipTo = checked;
    this.updateAddresses();
    this.cdr.detectChanges();
  }

  validateForms(): boolean {
    return this.soldToAddressForm?.valid && (!this.useShipTo || this.shipToAddressForm?.valid);
  }

  allowGoNext() {
    return this.validateForms()
      && !(this.cart.deliverySlot == null && !this.cart.allowedPickupPoints)
      && !(this.cart.allowedPickupPoints && !this.selectedPickupValue);
  }
}

export class LoggedUser {
  constructor(public logged: boolean) {

  }
}
