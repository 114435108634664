import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CartActions} from "@spartacus/cart/base/core";
import {from, Observable} from 'rxjs';
import {catchError, concatMap, map, mergeMap} from 'rxjs/operators';
import {openCloseSpinner} from "../util/valio-modals-utils";
import {makeErrorSerializable} from "../util/valio-serialization-utils";
import {
    ValioCartAddEntry,
    ValioCartAddEntrySuccess,
    ValioCartUpdateEntry, VALIO_CART_ADD_ENTRY,
    VALIO_CART_ADD_ENTRY_SUCCESS,
    VALIO_CART_UPDATE_ENTRY
} from "./valio-cart-entry.action";
import {ValioCartEntryConnector} from "./valio-cart-entry.connector";
import {ValioCart} from "./valio-cart.objects";
import {DOCUMENT} from "@angular/common";

@Injectable()
export class ValioCartEntryEffects {
  private updateEntry$: Observable<ValioCartAddEntrySuccess | CartActions.CartUpdateEntryFail>
  private addEntry$: Observable<ValioCartAddEntrySuccess | CartActions.CartAddEntryFail>
  private refreshWithoutProcesses2$: Observable<CartActions.LoadCartSuccess>
  constructor(
        protected actions$: Actions,
        protected cartEntryConnector2: ValioCartEntryConnector,
        @Inject(DOCUMENT) private document: Document
    ) {
      this.updateEntry$ = createEffect(() => this.actions$.pipe(
          ofType(VALIO_CART_UPDATE_ENTRY),
          map((action: ValioCartUpdateEntry) => action.payload),
          mergeMap(payload => {
              openCloseSpinner(this.document,true);
              return this.cartEntryConnector2
                .updateEntry(payload.userId, payload.cartId, payload.entry, payload.data)
                .pipe(
                  concatMap(cart => {
                    openCloseSpinner(this.document,false);
                    return [new ValioCartAddEntrySuccess({
                      userId: payload.userId,
                      cartId: payload.cartId,
                      cart: cart
                    })];
                  }),
                  catchError(error => {
                      return from([
                          new CartActions.CartUpdateEntryFail(makeErrorSerializable(error))
                        ]
                      )
                    }
                  )
                );
            }
          )
        )
      );


      this.addEntry$ = createEffect(() => this.actions$.pipe(
          ofType(VALIO_CART_ADD_ENTRY),
          map((action: ValioCartAddEntry) => action),
          mergeMap(action => {
              openCloseSpinner(document,true);
              return this.cartEntryConnector2
                .addEntry(
                  action.userId,
                  action.cartId,
                  action.productCode,
                  action.payload
                )
                .pipe(
                  concatMap(
                    (cart: ValioCart) => {
                      openCloseSpinner(document,false);
                      return [new ValioCartAddEntrySuccess({
                        userId: action.userId,
                        cartId: action.cartId,
                        cart: cart
                      })];
                    }
                  ),
                  catchError(error => {
                      return from([
                          new CartActions.CartAddEntryFail(makeErrorSerializable(error))
                        ]
                      )
                    }
                  )
                )
            }
          )
        )
      );


      this.refreshWithoutProcesses2$ = createEffect(() => this.actions$.pipe(
          ofType(VALIO_CART_ADD_ENTRY_SUCCESS),
          map((action: ValioCartAddEntrySuccess) => action.payload),
          map(payload =>
            new CartActions.LoadCartSuccess({
              userId: payload.userId,
              cartId: payload.cartId,
              cart: payload.cart,
              extraData: {active: true}
            })
          )
        )
      );
    }



}
