import {Component, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {Observable} from 'rxjs';
import {ValioNavigationService} from '../valio-navigation.service';

import {ValioNavigationNode} from '../valio-navigation-node.model';
import {ValioCmsMainNavigationComponent} from '../../../../models';

@Component({
  selector: 'valio-public-product-navigation-component',
  templateUrl: './valio-public-product-navigation.component.html',
})
export class ValioPublicProductNavigationComponent implements OnInit {
  node$: Observable<ValioNavigationNode>;

  constructor(
    protected componentData: CmsComponentData<ValioCmsMainNavigationComponent>,
    protected service: ValioNavigationService
  ) {
  }

  ngOnInit(): void {
    this.node$ = this.service.createNavigation(
      this.componentData.data$
    );
  }
}
