import {NgModule} from '@angular/core';
import {environment} from "../../environments/environment";
import {ValioOccEndpoints} from "../valio-occ-endpoints";
import {translationChunksConfig} from "@spartacus/assets";
import {Config, provideConfig, provideConfigFactory} from "@spartacus/core";
import {valioGlobalMessageConfigFactory} from "../features/global-messages/valio-global-message-config";
import {valioLayoutConfig} from "../features/cms/layout/config/valio-layout-config";
import {defaultCmsContentProviders, mediaConfig} from "@spartacus/storefront";
import {CART_FIELDS} from "../services/checkout/valio-occ-checkout.adapter";
export const cartFields = CART_FIELDS;

@NgModule({
  declarations: [],
  imports: [
  ],
  exports: [],
  providers: [
    provideConfig({
      pwa: {
        enabled: true,
        addToHomeScreen: true,
      },
    } as Config),
    provideConfig(valioLayoutConfig as Config),
    provideConfig(mediaConfig as Config),
    defaultCmsContentProviders,

    provideConfig({
      backend: {
        occ: {
          baseUrl: environment.backend.occ.baseUrl,
          prefix: '/rest/v2/',
          endpoints: {

            category: 'catalogs/categories/${categoryId}',
            product: 'products/${productCode}?fields=DEFAULT,images(FULL),stock(FULL),price(FULL),classifications(FULL),categories(FULL),storageInstructions,keyIngredients,manufacturer,ean,comparisonPrice(FULL),unit(FULL),salesUnitPrice(FULL),gdaPortion,variableWeight,freezeItem,contractual,paymentMode(FULL),closingTimePassed,nextDeliveryDate,brands(FULL),listPrice(FULL),ean,ingredients,additionalDescription,disposalInformation',
            manyProducts: 'products/products/${productCode}?fields=products(code,name,summary,price(FULL),categories(FULL),images(DEFAULT),stock(FULL),listPrice(FULL),comparisonPrice(FULL),salesUnitPrice(FULL),invoicingUnit(FULL),unit(FULL),baseUnit(FULL),partnerSupplier(FULL),purchasable,campaignProduct,newProduct,deliverable,deliveryPeriodFrom,deliveryPeriodTo,valioFacets,variableWeight,freezeItem,contractual,paymentMode(FULL),closingTimePassed,nextDeliveryDate,brands(FULL),ean),freeTextSearch',
            selectUnit: "users/${userId}/selectUnit/${unit}",
            dateFormat: 'yyyy-MM-dd',
            routeCalendar: 'users/${userId}/carts/${cartId}/routeCalendar?refreshCart=false',
            productSearch: 'products/search?fields=products(code,name,summary,price(FULL),categories(FULL),images(DEFAULT),stock(FULL),listPrice(FULL),comparisonPrice(FULL),salesUnitPrice(FULL),invoicingUnit(FULL),unit(FULL),baseUnit(FULL),partnerSupplier(FULL),purchasable,campaignProduct,newProduct,deliverable,deliveryPeriodFrom,deliveryPeriodTo,valioFacets,variableWeight,freezeItem,contractual,paymentMode(FULL),closingTimePassed,nextDeliveryDate,schoolMilkScheme,brands(FULL),ean),facets(FULL),breadcrumbs(FULL),pagination(DEFAULT),sorts(DEFAULT),freeTextSearch',
            suggestiveSearch: 'products/search?fields=products(code,name,images(DEFAULT),invoicingUnit(FULL),unit(FULL),purchasable,newProduct,closingTimePassed,ean,nextDeliveryDate,schoolMilkScheme,partnerSupplier(FULL)),facets(BASIC),breadcrumbs(BASIC),pagination(BASIC),sorts(BASIC),freeTextSearch',
            lastPurchased: 'products/lastPurchased?fields=products(code,name,summary,price(FULL),categories(FULL),images(DEFAULT),stock(FULL),listPrice(FULL),comparisonPrice(FULL),salesUnitPrice(FULL),invoicingUnit(FULL),unit(FULL),baseUnit(FULL),partnerSupplier(FULL),purchasable,campaignProduct,newProduct,deliverable,deliveryPeriodFrom,deliveryPeriodTo,valioFacets,variableWeight,freezeItem,contractual,paymentMode(FULL),closingTimePassed,nextDeliveryDate,brands(FULL),ean),freeTextSearch',
            categories: '/catalogs/categories?fields=id,name,url,subcategories(id,name,url,promoCategory,fiName),promoCategory,fiName',
            deliveryDate: 'users/${userId}/carts/${cartId}/deliveryDate?' + cartFields + '&refreshCart=false',
            deliveryAddress: 'users/${userId}/carts/${cartId}/addresses/delivery?' + cartFields,
            paymentAddress: 'users/${userId}/carts/${cartId}/addresses/paymentAddress?' + cartFields,
            phone: 'users/${userId}/carts/${cartId}/updateHeader?' + cartFields + '&refreshCart=false',
            email: 'users/${userId}/carts/${cartId}/updateHeader?' + cartFields + '&refreshCart=false',
            vatId: 'users/${userId}/carts/${cartId}/updateHeader?' + cartFields + '&refreshCart=false',
            deliverySlot: 'users/${userId}/carts/${cartId}/updateHeader?' + cartFields + '&refreshCart=false',
            deliveryMode: 'users/${userId}/carts/${cartId}/updateHeader?' + cartFields + '&refreshCart=false',
            pickupLocation: 'users/${userId}/carts/${cartId}/updateHeader?' + cartFields + '&refreshCart=false',
            partner: 'users/${userId}/carts/${cartId}/updateHeader?' + cartFields + '&refreshCart=false',
            cart: 'users/${userId}/carts/${cartId}?' + cartFields,
            createCart: 'users/${userId}/carts?' + cartFields,
            addEntries: 'users/${userId}/carts/${cartId}/entries?' + cartFields,
            addCsvEntries: 'users/${userId}/carts/${cartId}/csvEntries?fields=statusCode,entry(DEFAULT),quantity,quantityAdded,deliveryModeChanged,statusMessage',
            updateEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}?' + cartFields,
            cartDate: 'users/${userId}/carts/${cartId}/cartDate?' + cartFields,
            baseOrder: 'users/${userId}/carts/baseOrder?fields=allowedWeekDays,erpMessages(DEFAULT),entries(weekday,entries(newEntry,statusDisplay,quantity,product(DEFAULT),evenOddWeek,entryNumber,unit(DEFAULT),validForOrdering,updateable,external,editable,deletable,schoolMilkSupport,deleted,originalQuantity,poNumber,requestedDeliveryDate,salesendDate,erpMessages(DEFAULT),schoolMilkAllowed,orderType,lotPrice(FULL),statusDisplay),totalWeigth),blocks(blockStart,blockEnd,comment,sendEmail,email,entryNumber,deleted),baseorderId',
            carts: 'users/${userId}/carts?fields=carts(DEFAULT,entries(DEFAULT),unit(name,uid),totalPrice(formattedValue),totalItems,totalPrice(formattedValue),totalPriceWithoutTax(formattedValue),totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryCost(formattedValue),totalTax(formattedValue),net,user(BASIC))',
            orderTemplates: 'users/${userId}/carts?orderTemplates=true&fields=carts(code,name,schoolMilkAllowed,requestedDate,entries(DEFAULT))',
            createOrderTemplate: 'users/${userId}/carts/createOrderTemplate?fields=code,name,schoolMilkAllowed',
            saveOrderTemplate: 'users/${userId}/carts/${cartId}/orderTemplate?fields=code,name,schoolMilkAllowed,requestedDate,entries(DEFAULT)',
            orderTemplateToCart: 'users/${userId}/carts/${cartId}/orderTemplateToCart?fields=code,name,schoolMilkAllowed,requestedDate,entries(DEFAULT)',
            deliveryNotes: 'users/${userId}/deliveryNotes',
            deliveryNoteProducts: 'users/${userId}/deliveryNoteProducts',
            restrictedAssortment: 'users/${userId}/restrictedAssortment',
            createReclamation: 'users/${userId}/createReclamation',
            sendNewContractRequest: 'users/${userId}/sendNewContractRequest',
            removeOrderTemplate: 'users/${userId}/carts/${templateId}/deleteOrderTemplate',
            checkIfUserExists: 'usersExist/${userId}',
            applyForPartner: 'users/${userId}/sendNewPartnerRequest/${partner}',
            analyticsConfig: 'environment/analytics',
            boughtTogether: 'products/boughtTogether?fields=products(code,name,summary,price(FULL),categories(FULL),images(DEFAULT),stock(FULL),listPrice(FULL),comparisonPrice(FULL),salesUnitPrice(FULL),invoicingUnit(FULL),unit(FULL),baseUnit(FULL),partnerSupplier(FULL),purchasable,campaignProduct,newProduct,deliverable,deliveryPeriodFrom,deliveryPeriodTo,valioFacets,variableWeight,freezeItem,contractual,paymentMode(FULL),closingTimePassed,nextDeliveryDate,brands(FULL),ean),freeTextSearch',
            topSellers: 'products/topSellers?fields=products(code,name,summary,price(FULL),categories(FULL),images(DEFAULT),stock(FULL),listPrice(FULL),comparisonPrice(FULL),salesUnitPrice(FULL),invoicingUnit(FULL),unit(FULL),baseUnit(FULL),partnerSupplier(FULL),purchasable,campaignProduct,newProduct,deliverable,deliveryPeriodFrom,deliveryPeriodTo,valioFacets,variableWeight,freezeItem,contractual,paymentMode(FULL),closingTimePassed,nextDeliveryDate,brands(FULL),ean),freeTextSearch',
            vendor: 'users/${userId}/vendor'
           } as ValioOccEndpoints
        },
        loadingScopes: {
          product: {
            details: {
              include: [],
            },
          },
        }
      },
      authentication: {
        client_id: 'mobile_android',
        client_secret: 'secret'
      },
      context: {
        urlParameters: ['language', 'partner'],
        language: ['fi', 'en', 'sv'],
        currency: ['EUR'],
        baseSite: ['valiob2b'],
        partner: ['all']
      },
      i18n: {
        chunks: translationChunksConfig,
        fallbackLang: 'en'
      },
      features: {
        level: '2.0',
        consignmentTracking: true
      },
      breakpoints: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
      },
      view: {
        infiniteScroll: {
          active: true,
          productLimit: 1000,
          showMoreButton: false
        }
      }

    } as Config),
    provideConfig({
      routing: {
        routes: {
          logoutPreview: {paths: ['logoutPreview']},
          modifyOrderTemplate: {paths: ['modifyOrderTemplate']},
          termsOfService: {paths: ['kayttoehdot']},
          privacyPolicy: {paths: ['tietosuojaseloste']},
          serviceInformation: {paths: ['tietoa-palvelusta']},
          partnersPage: {paths: ['sopimusasiakkuus']},
          product: {
            paths: [
              'product/:productCode/:prettyUrl',
              'product/:productCode/:name'
            ],
          }
        }
      }
    }),
    provideConfigFactory(valioGlobalMessageConfigFactory)
  ]
})
export class SpartacusConfigurationModule  {
}
