import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  AuthActions,
  GlobalMessageActions, LoggerService,
  OCC_USER_ID_ANONYMOUS,
  SiteContextActions, tryNormalizeHttpError,
  UserActions,
  withdrawOn,
} from '@spartacus/core';
import {from, Observable, of} from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import {CheckoutActions} from '../actions';
import {
    CheckoutDeliveryAddressConnector, CheckoutDeliveryModesConnector,
    CheckoutPaymentConnector
} from "@spartacus/checkout/base/core";
import {CheckoutCostCenterConnector} from "@spartacus/checkout/b2b/core";
import {CartActions} from "@spartacus/cart/base/core";
import {CheckoutState} from "@spartacus/checkout/base/root";
import {OrderConnector} from "@spartacus/order/core";
import {ValioOccCheckoutAdapter} from "../../../../../services/checkout/valio-occ-checkout.adapter";

@Injectable()
export class CheckoutEffects {
  private readonly contextChange$;
  private addDeliveryAddress$: Observable<| UserActions.LoadUserAddresses | CheckoutActions.SetDeliveryAddress | CheckoutActions.AddDeliveryAddressFail>
  private setDeliveryAddress$: Observable<
    | CheckoutActions.SetDeliveryAddressSuccess
    | CheckoutActions.ClearSupportedDeliveryModes
    | CheckoutActions.ClearCheckoutDeliveryMode
    | CheckoutActions.ResetLoadSupportedDeliveryModesProcess
    | CheckoutActions.LoadSupportedDeliveryModes
    | CheckoutActions.SetDeliveryAddressFail
  >
  private loadSupportedDeliveryModes$: Observable<
    | CheckoutActions.LoadSupportedDeliveryModesSuccess
    | CheckoutActions.LoadSupportedDeliveryModesFail>

  private clearCheckoutMiscsDataOnLanguageChange$: Observable<
    | CheckoutActions.CheckoutClearMiscsData
    | CheckoutActions.ResetLoadSupportedDeliveryModesProcess
    | CheckoutActions.ResetLoadPaymentTypesProcess
  >
  private clearCheckoutDataOnLogout$: Observable<
    | CheckoutActions.ClearCheckoutData
    | CheckoutActions.ResetLoadSupportedDeliveryModesProcess
    | CheckoutActions.ResetLoadPaymentTypesProcess
  >
  private clearDeliveryModesOnCurrencyChange$: Observable<CheckoutActions.ClearSupportedDeliveryModes>

  private clearCheckoutDataOnLogin$: Observable<CheckoutActions.ClearCheckoutData>

  private setDeliveryMode$: Observable<
    | CheckoutActions.SetDeliveryModeSuccess
    | CheckoutActions.SetDeliveryModeFail
    | CartActions.LoadCart
  >

  private createPaymentDetails$: Observable<
    | UserActions.LoadUserPaymentMethods
    | CheckoutActions.CreatePaymentDetailsSuccess
    | CheckoutActions.CreatePaymentDetailsFail
  >

  private setPaymentDetails$: Observable<
    | CheckoutActions.SetPaymentDetailsSuccess
    | CheckoutActions.SetPaymentDetailsFail
  >
  private placeOrder$: Observable<
    | CheckoutActions.PlaceOrderSuccess
    | GlobalMessageActions.AddMessage
    | CheckoutActions.PlaceOrderFail
    | CartActions.RemoveCart
  >
  private loadCheckoutDetails$: Observable<
    | CheckoutActions.LoadCheckoutDetailsSuccess
    | CheckoutActions.LoadCheckoutDetailsFail
  >
  private reloadDetailsOnMergeCart$: Observable<CheckoutActions.LoadCheckoutDetails>
  private clearCheckoutDeliveryAddress$: Observable<
    | CheckoutActions.ClearCheckoutDeliveryAddressFail
    | CheckoutActions.ClearCheckoutDeliveryAddressSuccess
  >
  private clearCheckoutDeliveryMode$: Observable<
    | CheckoutActions.ClearCheckoutDeliveryModeFail
    | CheckoutActions.ClearCheckoutDeliveryModeSuccess
    | CartActions.LoadCart
  >
  private setCostCenter$: Observable<
    | CheckoutActions.SetCostCenterSuccess
    | CheckoutActions.SetCostCenterFail
    | CheckoutActions.ClearCheckoutDeliveryAddress
    | CartActions.LoadCart
  >
  constructor(
    private actions$: Actions,
    private checkoutPaymentConnector: CheckoutPaymentConnector,
    private checkoutCostCenterConnector: CheckoutCostCenterConnector,
    private orderConnector: OrderConnector,
    private checkoutDeliveryAddressConnector: CheckoutDeliveryAddressConnector,
    private checkoutDeliveryModesConnector: CheckoutDeliveryModesConnector,
    private occCheckoutAdapter: ValioOccCheckoutAdapter,
    private loggerService: LoggerService
  ) {
    this.contextChange$ = this.actions$.pipe(
      ofType(
        SiteContextActions.CURRENCY_CHANGE,
        SiteContextActions.LANGUAGE_CHANGE
      ));
    this.addDeliveryAddress$ = createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.ADD_DELIVERY_ADDRESS),
        map((action: CheckoutActions.AddDeliveryAddress) => action.payload),
        mergeMap((payload) =>
          this.checkoutDeliveryAddressConnector
            .createAddress(payload.userId, payload.cartId, payload.address)
            .pipe(
              mergeMap((address) => {
                address['titleCode'] = payload.address.titleCode;
                if (payload.address.region && payload.address.region.isocodeShort) {
                  Object.assign(address.region, {
                    isocodeShort: payload.address.region.isocodeShort,
                  });
                }
                if (payload.userId === OCC_USER_ID_ANONYMOUS) {
                  return [
                    new CheckoutActions.SetDeliveryAddress({
                      userId: payload.userId,
                      cartId: payload.cartId,
                      address: address,
                    }),
                  ];
                } else {
                  return [
                    new UserActions.LoadUserAddresses(payload.userId),
                    new CheckoutActions.SetDeliveryAddress({
                      userId: payload.userId,
                      cartId: payload.cartId,
                      address: address,
                    }),
                  ];
                }
              }),
              catchError((error) =>
                of(
                  new CheckoutActions.AddDeliveryAddressFail(
                    tryNormalizeHttpError(error,this.loggerService)
                  )
                )
              )
            )
        ),
        withdrawOn(this.contextChange$)
      )
    );
    this.setDeliveryAddress$ = createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.SET_DELIVERY_ADDRESS),
        map((action: any) => action.payload),
        mergeMap((payload) => {
          return this.checkoutDeliveryAddressConnector
            .setAddress(payload.userId, payload.cartId, payload.address.id)
            .pipe(
              mergeMap(() => [
                new CheckoutActions.SetDeliveryAddressSuccess(payload.address),
                new CheckoutActions.ClearCheckoutDeliveryMode({
                  userId: payload.userId,
                  cartId: payload.cartId,
                }),
                new CheckoutActions.ClearSupportedDeliveryModes(),
                new CheckoutActions.ResetLoadSupportedDeliveryModesProcess(),
                new CheckoutActions.LoadSupportedDeliveryModes({
                  userId: payload.userId,
                  cartId: payload.cartId,
                }),
              ]),
              catchError((error) =>
                of(
                  new CheckoutActions.SetDeliveryAddressFail(
                    tryNormalizeHttpError(error,this.loggerService)
                  )
                )
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );
    this.loadSupportedDeliveryModes$ = createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.LOAD_SUPPORTED_DELIVERY_MODES),
        map((action: any) => action.payload),
        mergeMap((payload) => {
          return this.checkoutDeliveryModesConnector
            .getSupportedModes(payload.userId, payload.cartId)
            .pipe(
              map((data) => {
                return new CheckoutActions.LoadSupportedDeliveryModesSuccess(data);
              }),
              catchError((error) =>
                of(
                  new CheckoutActions.LoadSupportedDeliveryModesFail(
                    tryNormalizeHttpError(error,this.loggerService)
                  )
                )
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );

    this.clearCheckoutMiscsDataOnLanguageChange$ = createEffect(() => this.actions$.pipe(
        ofType(SiteContextActions.LANGUAGE_CHANGE),
        mergeMap(() => [
          new CheckoutActions.ResetLoadSupportedDeliveryModesProcess(),
          new CheckoutActions.ResetLoadPaymentTypesProcess(),
          new CheckoutActions.CheckoutClearMiscsData(),
        ])
      )
    );
    this.clearDeliveryModesOnCurrencyChange$ = createEffect(() => this.actions$.pipe(
        ofType(SiteContextActions.CURRENCY_CHANGE),
        map(() => new CheckoutActions.ClearSupportedDeliveryModes())
      )
    );


    this.clearCheckoutDataOnLogout$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.LOGOUT),
        mergeMap(() => [
          new CheckoutActions.ClearCheckoutData(),
          new CheckoutActions.ResetLoadSupportedDeliveryModesProcess(),
          new CheckoutActions.ResetLoadPaymentTypesProcess(),
        ])
      )
    );

    this.clearCheckoutDataOnLogin$= createEffect(() => this.actions$.pipe(
        ofType(AuthActions.LOGIN),
        map(() => new CheckoutActions.ClearCheckoutData())
      )
    );


    this.setDeliveryMode$= createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.SET_DELIVERY_MODE),
        map((action: any) => action.payload),
        mergeMap((payload) => {
          return this.checkoutDeliveryModesConnector
            .setMode(payload.userId, payload.cartId, payload.selectedModeId)
            .pipe(
              mergeMap(() => {
                return [
                  new CheckoutActions.SetDeliveryModeSuccess(
                    payload.selectedModeId
                  ),
                  new CartActions.LoadCart({
                    userId: payload.userId,
                    cartId: payload.cartId,
                  }),
                ];
              }),
              catchError((error) =>
                of(
                  new CheckoutActions.SetDeliveryModeFail( tryNormalizeHttpError(error,this.loggerService))
                )
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );


    this.createPaymentDetails$ = createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.CREATE_PAYMENT_DETAILS),
        map((action: any) => action.payload),
        mergeMap((payload) => {
          // get information for creating a subscription directly with payment provider
          return this.checkoutPaymentConnector
            .createPaymentDetails(payload.userId, payload.cartId, payload.paymentDetails)
            .pipe(
              mergeMap((details) => {
                if (payload.userId === OCC_USER_ID_ANONYMOUS) {
                  return [new CheckoutActions.CreatePaymentDetailsSuccess(details)];
                } else {
                  return [
                    new UserActions.LoadUserPaymentMethods(payload.userId),
                    new CheckoutActions.CreatePaymentDetailsSuccess(details),
                  ];
                }
              }),
              catchError((error) =>
                of(
                  new CheckoutActions.CreatePaymentDetailsFail(
                    tryNormalizeHttpError(error,this.loggerService)
                  )
                )
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );

    this.setPaymentDetails$= createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.SET_PAYMENT_DETAILS),
        map((action: any) => action.payload),
        mergeMap((payload) => {
          return this.checkoutPaymentConnector
            .setPaymentDetails(payload.userId, payload.cartId, payload.paymentDetails.id)
            .pipe(
              map(
                () =>
                  new CheckoutActions.SetPaymentDetailsSuccess(
                    payload.paymentDetails
                  )
              ),
              catchError((error) =>
                of(
                  new CheckoutActions.SetPaymentDetailsFail(
                    tryNormalizeHttpError(error,this.loggerService)
                  )
                )
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );

    this.placeOrder$ = createEffect(() =>
      this.actions$.pipe(
        ofType(CheckoutActions.PLACE_ORDER),
        map((action: any) => action.payload),
        mergeMap((payload) => {
          return this.orderConnector
            .placeOrder(payload.userId, payload.cartId, payload.termsChecked)
            .pipe(
              switchMap((data) => [
                new CartActions.RemoveCart({cartId: payload.cartId}),
                new CheckoutActions.PlaceOrderSuccess(data),
              ]),
              catchError((error) =>
                of(new CheckoutActions.PlaceOrderFail( tryNormalizeHttpError(error,this.loggerService)))
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );


    this.loadCheckoutDetails$ = createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.LOAD_CHECKOUT_DETAILS),
        map((action: CheckoutActions.LoadCheckoutDetails) => action.payload),
        mergeMap((payload) => {
          return this.occCheckoutAdapter
            .loadCheckoutDetails(payload.userId, payload.cartId)
            .pipe(
              map(
                (data: CheckoutState) =>
                  new CheckoutActions.LoadCheckoutDetailsSuccess(data)
              ),
              catchError((error) =>
                of(
                  new CheckoutActions.LoadCheckoutDetailsFail(
                    tryNormalizeHttpError(error,this.loggerService)
                  )
                )
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );


    this.reloadDetailsOnMergeCart$= createEffect(() => this.actions$.pipe(
        ofType(CartActions.MERGE_CART_SUCCESS),
        map((action: CartActions.MergeCartSuccess) => action.payload),
        map((payload) => {
          return new CheckoutActions.LoadCheckoutDetails({
            userId: payload.userId,
            cartId: payload.cartId,
          });
        })
      )
    );

    this.clearCheckoutDeliveryAddress$ = createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.CLEAR_CHECKOUT_DELIVERY_ADDRESS),
        map(
          (action: CheckoutActions.ClearCheckoutDeliveryAddress) => action.payload
        ),
        filter((payload) => Boolean(payload.cartId)),
        switchMap((payload) => {
          return this.checkoutDeliveryAddressConnector.clearCheckoutDeliveryAddress(payload.userId, payload.cartId)
            .pipe(
              map(() => new CheckoutActions.ClearCheckoutDeliveryAddressSuccess()),
              catchError((error) =>
                of(
                  new CheckoutActions.ClearCheckoutDeliveryAddressFail(
                    tryNormalizeHttpError(error,this.loggerService)
                  )
                )
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );


    this.clearCheckoutDeliveryMode$ = createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.CLEAR_CHECKOUT_DELIVERY_MODE),
        map((action: CheckoutActions.ClearCheckoutDeliveryMode) => action.payload),
        filter((payload) => Boolean(payload.cartId)),
        concatMap((payload) => {
          return this.checkoutDeliveryModesConnector.clearCheckoutDeliveryMode(payload.userId, payload.cartId)
            .pipe(
              mergeMap(() => [
                new CheckoutActions.ClearCheckoutDeliveryModeSuccess({
                  ...payload,
                }),
                new CartActions.LoadCart({
                  cartId: payload.cartId,
                  userId: payload.userId,
                }),
              ]),
              catchError((error) =>
                from([
                  new CheckoutActions.ClearCheckoutDeliveryModeFail({
                    cartId: payload.cartId,
                    userId: payload.userId,
                    error:  tryNormalizeHttpError(error,this.loggerService)
                  }),
                  new CartActions.LoadCart({
                    cartId: payload.cartId,
                    userId: payload.userId,
                  }),
                ])
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );

    this.setCostCenter$= createEffect(() => this.actions$.pipe(
        ofType(CheckoutActions.SET_COST_CENTER),
        map((action: CheckoutActions.SetCostCenter) => action.payload),
        switchMap((payload) => {
          return this.checkoutCostCenterConnector
            .setCostCenter(payload.userId, payload.cartId, payload.costCenterId)
            .pipe(
              mergeMap((_data) => [
                new CartActions.LoadCart({
                  cartId: payload.cartId,
                  userId: payload.userId,
                }),
                new CheckoutActions.SetCostCenterSuccess(payload.costCenterId),
                new CheckoutActions.ClearCheckoutDeliveryAddress({
                  userId: payload.userId,
                  cartId: payload.cartId,
                }),
              ]),
              catchError((error) =>
                of(new CheckoutActions.SetCostCenterFail( tryNormalizeHttpError(error,this.loggerService)))
              )
            );
        }),
        withdrawOn(this.contextChange$)
      )
    );

  }


}
