import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'valio-cx-browser-check',
  templateUrl: './valio-browser-check.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioBrowserCheckComponent {
  closed: boolean = false;

  constructor(  @Inject(DOCUMENT) protected document: Document) {
  }

  show(): boolean {
    const userAgent = this.document.defaultView.window.navigator.userAgent;
    console.log(userAgent);
    return /msie\s|trident\//i.test(userAgent);
  }

  close() {
    this.closed = true;
  }
}
