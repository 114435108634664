import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductConnector} from "@spartacus/core";
import {ValioCategory, ValioProduct} from "../../models";
import {ValioOccProductAdapter} from "./valio-occ-product.adapter";

@Injectable({
  providedIn: 'root',
})
export class ValioProductConnector extends ProductConnector {
  constructor(protected productAdapter: ValioOccProductAdapter) {
    super(productAdapter);
  }

  getManyProducts(productCode: string[]): Observable<ValioProduct[]> {
    return (this.productAdapter).getManyProducts(productCode);
  }

  getCategoryById(categoryId: string): Observable<ValioCategory> {
    return this.productAdapter.getCategoryById(categoryId);
  }

}
