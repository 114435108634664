import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {
  VALIO_SET_RESTRICTED_ASSORTMENT,
  ValioSetRestrictedAssortment,
  ValioSetRestrictedAssortmentSuccessful
} from "./valio-restricted-assortment.action";
import {ProductActions, UserActions} from "@spartacus/core";
import {Router} from "@angular/router";

@Injectable()
export class ValioRestrictedAssortmentEffects {

  private activateRestrictedAssortment$: Observable<ValioSetRestrictedAssortmentSuccessful | ProductActions.ClearProductSearchResult>

  constructor(protected actions$: Actions,
              protected router: Router) {
    this.activateRestrictedAssortment$ = createEffect(() => this.actions$.pipe(
        ofType(VALIO_SET_RESTRICTED_ASSORTMENT),
        concatMap((action: ValioSetRestrictedAssortment) => {
          return [
            new ValioSetRestrictedAssortmentSuccessful(action.value),
            new ProductActions.ClearProductSearchResult({
              clearSearchboxResults: true,
            })
          ];
        })
      )
    );
  }


}
