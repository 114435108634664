import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from "rxjs";
import {catchError, concatMap, map, switchMap} from "rxjs/operators";
import {ValioProductSearchConnector} from "../../product/valio-product-search.connector";
import {
  LOAD_BOUGHT_TOGETHER,
  ValioLoadBoughtTogether,
  ValioLoadBoughtTogetherFail,
  ValioLoadBoughtTogetherSuccess
} from "./valio-bought-together.action";
import {ValioCartService} from "../../cart/valio-cart.service";
import {ValioCart, ValioCartEntry} from "../../cart/valio-cart.objects";

@Injectable()
export class ValioBoughtTogetherEffects {

  private $activeCart: Observable<ValioCart>;
  private loadBoughtTogether$: Observable<ValioLoadBoughtTogetherSuccess | ValioLoadBoughtTogetherFail>

  constructor(
    protected actions$: Actions,
    protected productSearchConnector: ValioProductSearchConnector,
    protected cartService: ValioCartService
  ) {
    this.$activeCart = this.cartService.getActive();
    this.loadBoughtTogether$ = createEffect(() => this.actions$.pipe(
        ofType(LOAD_BOUGHT_TOGETHER),
        map((action: ValioLoadBoughtTogether) => action),
        switchMap((action: ValioLoadBoughtTogether) => {
            // Payload contains either product code or cartId+entry number
            if (action.payload.productCode) {
              return this.productSearchConnector.loadBoughtTogetherProducts(action.payload.productCode)
                .pipe(map(products => new ValioLoadBoughtTogetherSuccess(products)));
            } else if (action.payload.entry && action.payload.cartId) {
              return this.$activeCart.pipe(concatMap(cart => {
                const entry: ValioCartEntry = cart.entries?.find(e => e.entryNumber.toString() == action.payload.entry);
                return this.productSearchConnector.loadBoughtTogetherProducts(entry?.product.code)
                  .pipe(map(products => new ValioLoadBoughtTogetherSuccess(products)));
              }));
            } else {
              // Load all
              return this.productSearchConnector.loadBoughtTogetherProducts(null)
                .pipe(map(products => new ValioLoadBoughtTogetherSuccess(products)));
            }
          }
        ), catchError(error => {
          console.error("Error in loadBoughtTogether$: " + error);
          return of(new ValioLoadBoughtTogetherFail());
        })
      )
    );
  }


}

