import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from "rxjs";
import {catchError, concatMap, map} from "rxjs/operators";
import {
  LOAD_LAST_PURCHASES,
  ValioLoadLastPurchases,
  ValioLoadLastPurchasesFail,
  ValioLoadLastPurchasesSuccess
} from "./valio-last-purchases.action";
import {ValioProductSearchConnector} from "../product/valio-product-search.connector";

@Injectable()
export class ValioLastPurchasesEffects {
  private loadLastPurchases$: Observable<ValioLoadLastPurchasesSuccess | ValioLoadLastPurchasesFail>

  constructor(
    protected actions$: Actions,
    protected productSearchConnector: ValioProductSearchConnector,
  ) {
    this.loadLastPurchases$ = createEffect(() => this.actions$.pipe(
        ofType(LOAD_LAST_PURCHASES),
        map((action: ValioLoadLastPurchases) => action),
        concatMap(() => {
            return this.productSearchConnector.loadLastPurchasedProducts()
              .pipe(
                map(products => new ValioLoadLastPurchasesSuccess(products))
              );
          }
        ), catchError(error => {
          console.error("Error in loadLastPurchases$: " + error);
          return of(new ValioLoadLastPurchasesFail());
        })
      )
    );
  }


}

