import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
// TODO:Spartacus - // TODO:Spartacus - Class UserService has been removed and is no longer part of the public API.
import {
  AuthService,
  StateWithProcess,
  StateWithUser,
  UserIdService
} from '@spartacus/core';

import {SelectUnit} from './valio-user-actions';
import {Observable, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ValioUserConnector} from "./valio-user.connector";
import {
  ValioDeliveryNote,
  ValioDeliveryNoteList,
  ValioDeliveryNoteProductList,
  ValioOrderHistoryList,
  ValioPartnerApplication,
  ValioReclamationCreate, ValioUser,
} from "../../models/misc.model";
import {Partner} from "../cart/valio-cart.objects";
import { UserAccountFacade } from '@spartacus/user/account/root';

@Injectable({
  providedIn: 'root',
})
export class ValioUserService implements OnDestroy {
  private currentUserId: string;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected userConnector: ValioUserConnector,
    protected store: Store<StateWithUser | StateWithProcess<void>>,
    protected userIdService: UserIdService,
    protected authService: AuthService,
    protected userAccountFacade: UserAccountFacade

  ) {
    this.subscriptions.add(
      this.userIdService.getUserId().subscribe((userId) => {
        this.currentUserId = userId;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Returns a user
   */
  selectUnit(unit: string): void {
    this.store.dispatch(new SelectUnit(this.currentUserId, unit));
  }

  /**
   * Retrieves an order list
   * @param from From date
   * @param to To date
   * @param poNumber Purchase order number
   * @param sort Sort
   * @param metaDataOnly Metadata only flag
   */
  getOrderList(from: Date, to?: Date, poNumber?: string, sort ?: string, metaDataOnly?: boolean): Observable<ValioOrderHistoryList> {
    return this.userIdService
      .getUserId()
      .pipe(switchMap(occUserId => {
            return this.userConnector
              .getHistory(
                occUserId,
                sort,
                from,
                to,
                poNumber,
                metaDataOnly
              )
          }
        )
      );
  }

  isAnonymous(): Observable<boolean> {
    return this.authService.isUserLoggedIn().pipe(map(isLoggedIn=> !isLoggedIn));
  }

  isSignedIn(): Observable<boolean> {
    return this.authService.isUserLoggedIn();
  }

  isContractCustomer(): Observable<boolean> {
    return this.userAccountFacade.get()
      .pipe(map(user => user as ValioUser))
      .pipe(map(user => {
        return user?.activeB2bUnit != null && !user?.activeB2bUnit.nonContractUnit;
      })
    );
  }

  getDeliveryNoteList(selectedDate: Date, toDate: Date): Observable<ValioDeliveryNoteList> {
    return this.userConnector.getDeliveryNoteList(this.currentUserId, selectedDate, toDate);
  }

  getDeliveryNoteProducts(deliveryNote: ValioDeliveryNote): Observable<ValioDeliveryNoteProductList> {
    return this.userConnector.getDeliveryNoteProducts(this.currentUserId, deliveryNote);
  }

  setRestrictedAssortment(value: boolean): Observable<boolean> {
    return this.userConnector.setRestrictedAssortment(this.currentUserId, value);
  }

  isRestrictedAssortment(): Observable<boolean> {
    return this.userConnector.isRestrictedAssortment(this.currentUserId)
  }

  sendReclamation(reclamation: ValioReclamationCreate): Observable<string> {
    return this.userConnector.sendReclamation(this.currentUserId, reclamation);
  }

  sendNewContractRequest(form: any): Observable<boolean> {
    return this.userConnector.sendNewContractRequest(this.currentUserId, form);
  }

  applyForPartner(partner: Partner, data: ValioPartnerApplication): Observable<boolean> {
    return this.userConnector.applyForPartner(this.currentUserId, partner, data);
  }

}
