import {Component, OnInit} from '@angular/core';
import {AuthService, User} from '@spartacus/core';
import {Observable, of} from 'rxjs';


@Component({
  selector: 'valio-cx-login',
  templateUrl: './valio-login.component.html',
})
export class ValioLoginComponent implements OnInit {
  //user$: Observable<User>;
  loggedIn$: Observable<boolean>;

  constructor(protected auth: AuthService) {
  }

  ngOnInit(): void {
    this.loggedIn$ = this.auth.isUserLoggedIn();
  }
}
