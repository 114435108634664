import {Injectable} from "@angular/core";

import {NonNullableFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomFormValidators} from "@spartacus/storefront";

import {ValioRegisterExistingComponent} from "../../user/register/valio-register-existing.component";
import {ResetPasswordComponentService} from "@spartacus/user/profile/components";
import {BehaviorSubject} from "rxjs";
import {UserPasswordFacade} from "@spartacus/user/profile/root";
import {GlobalMessageService, RoutingService} from "@spartacus/core";

@Injectable({
  providedIn: 'root',
})
export class ValioUpdatePasswordComponentService extends ResetPasswordComponentService {
  isSubmitted$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(userPasswordService: UserPasswordFacade, routingService: RoutingService, globalMessage: GlobalMessageService,
              protected fb: NonNullableFormBuilder) {
    super(userPasswordService, routingService, globalMessage);
    this.form = this.fb.group(
      {
        password: [
          '',
          [Validators.required, ValioRegisterExistingComponent.passwordValidator],
        ],
        passwordConfirm: ['', [Validators.required]],
        termsandconditions: [false, Validators.requiredTrue]
      },
      {validators: CustomFormValidators.passwordsMustMatch(
          'password',
          'passwordConfirm'
        )}
    )
  }

  resetPassword(token: string) {
    this.isSubmitted$.next(true)
    super.resetPassword(token);
  }
}
