import {LayoutConfig} from "@spartacus/storefront";

export const valioLayoutConfig: LayoutConfig = {
  layoutSlots: {
    // overriding Spartacus page templates
    header: {
      lg: {
        slots: [
          'SiteLogo',
          'SiteLinks',
          'SiteLogin',
        ]
      },
      slots: [
        'PreHeader',
        'SiteLogo',
        'SiteLogin'
      ]
    },
    navigation: {
      lg: {slots: []},
      slots: [
        'NavigationBar',
        'SiteLinks'
      ]
    },
    footer: {
      slots: [
        'Footer'
      ]
    },
    LandingPage2Template: {
      slots: [
        'SidePanel',
        'Section1',
      ]
    },
    ContentPage1Template: {
      slots: [
        'Section2A',
        'Section2B'
      ]
    },
    CategoryPageTemplate: {
      slots: [
        'Section1',
        'Section2',
        'Section3'
      ]
    },
    ProductListPageTemplate: {
      slots: [
        'SidePanel',
        'ProductListSlot',
      ]
    },
    ProductGridPageTemplate: {
      slots: [
        'SidePanel',
        'ProductGridSlot',
      ]
    },
    SearchResultsListPageTemplate: {
      slots: [
        'SidePanel',
        'SearchResultsListSlot',
      ]
    },
    SearchResultsGridPageTemplate: {
      slots: [
        'SidePanel',
        'SearchResultsGridSlot',
      ]
    },
    ProductDetailsPageTemplate: {
      slots: [
        'MainNavigation',
        'Summary',
        'Nutrition',
      ]
    },
    CartPageTemplate: {
      slots: [
        'MainNavigation',
        'TopContent',
        'BodyContent',
        'CenterRightContentSlot'
      ]
    },
    AccountPageTemplate: {
      slots: [
        'SideContent',
        'BodyContent'
      ]
    },
    LoginPageTemplate: {
      slots: [
        'LeftContentSlot',
        'RightContentSlot'
      ]
    },
    ErrorPageTemplate: {
      slots: [
        'TopContent',
        'MiddleContent',
        'BottomContent'
      ]
    },
    OrderConfirmationPageTemplate: {
      slots: [
        'MainNavigation',
        'TopContent',
        'BodyContent',
        'SideContent']
    },
    MultiStepCheckoutSummaryPageTemplate: {
      slots: [
        'MainNavigation',
        'TopContent',
        'BodyContent',
        'SideContent',
        'BottomContent'
      ]
    },
    CheckoutLoginPageTemplate: {
      slots: [
        'RightContentSlot'
      ]
    },

    // Valo specific page templates
    PartnersPageTemplate: {
      slots: [
        'Section1',
        'Section2A',
        'Section2B',
        'Section3',
      ]
    },
    RegisterPageTemplate: {
      slots: [
        'BodyContent',
        'BottomContent'
      ]
    },
    OrderTemplatePageTemplate: {
      slots: [
        'SideContent',
        'BodyContent'
      ]
    },
    NewsPageTemplate: {
      slots: [
        'Section2A',
        'NewsSection',
        'Section2B'
      ]
    },
    ContentPageBlueTemplate: {
      slots: [
        'Section1',
        'Section2A',
        'Section2B',
        'Section3'
      ]
    }
  }
} as LayoutConfig
