import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ValioOccEnvironmentAdapter} from "./valio-occ-environment.adapter";
import {AnalyticsConfig} from "./valio-environment.objects";

@Injectable({
  providedIn: 'root',
})
export class ValioEnvironmentConnector {

  constructor(protected adapter: ValioOccEnvironmentAdapter) {
  }

  getAnalyticsConfig(): Observable<AnalyticsConfig> {
    return (this.adapter as ValioOccEnvironmentAdapter).getAnalyticsConfig();
  }
}
