import {NgModule} from "@angular/core";
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forRoot([], {
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledNonBlocking'
})
  ]
})
export class ValioAppRoutingModule {
}

