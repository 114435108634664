import {Action} from "@ngrx/store";
import {AnalyticsConfig} from "./valio-environment.objects";

export const LOAD_ANALYTICS = '[Environment] Load Analytics';
export const LOAD_ANALYTICS_FAIL = '[Environment] Load Analytics Fail';
export const LOAD_ANALYTICS_SUCCESS = '[Environment] Load Analytics Success';


export class LoadAnalytics implements Action {
  readonly type = LOAD_ANALYTICS;

  constructor() {
  }
}

export class LoadAnalyticsFail implements Action {
  readonly type = LOAD_ANALYTICS_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadAnalyticsSuccess implements Action {
  readonly type = LOAD_ANALYTICS_SUCCESS;

  constructor(public payload: AnalyticsConfig) {

  }
}

export type ValioEnvironmentAction =
  | LoadAnalytics
  | LoadAnalyticsFail
  | LoadAnalyticsSuccess;
