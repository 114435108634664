import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {AddToCartComponent} from "@spartacus/cart/base/components/add-to-cart";
import {CmsAddToCartComponent, EventService, Product} from '@spartacus/core';
import {CmsComponentData, CurrentProductService} from "@spartacus/storefront";
import {Subscription} from "rxjs";
import {ValioProduct} from "../../../../models";
import {ValioGoogleAnalyticsService} from "../../../../services/analytics/valio-google-analytics.service";
import {ValioCartEntry, ValioCartEntryUpdateData} from '../../../../services/cart/valio-cart.objects';
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {DateUtils} from "../../../misc/util/date-utils";

@Component({
    selector: 'valio-cx-add-to-cart',
    templateUrl: './valio-add-to-cart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioAddToCartComponent extends AddToCartComponent {

    declare product: ValioProduct;
    showAddToCart: boolean = false;
    showAddToCartAndQuantity: boolean = false;
    itemAddedToCartLabel: string;
    oldQuantity: number = 0;

    @Input() set setProduct(product: Product) {
        this.productCode = product.code;
        this.product = product;
    }

    @Input() set setShowAddToCartAndQuantity(showAddToCartAndQuantity: boolean) {
        this.showAddToCartAndQuantity = showAddToCartAndQuantity;
    }

    constructor(
        currentProductService: CurrentProductService,
        protected cartService: ValioCartService,
        protected component: CmsComponentData<CmsAddToCartComponent>,
        protected eventService: EventService,
        protected cdr: ChangeDetectorRef,
        protected googleAnalyticsService: ValioGoogleAnalyticsService
    ) {
        super(currentProductService, cdr, cartService, component, eventService);
        if (!this.subscription) {
            this.subscription = new Subscription();
        }
    }

    getProductCode(): string {
        return this.productCode;
    }

    isShowQuantity(): boolean {
        return this.showQuantity;
    }

    isShowAddToCart(): boolean {
        return this.showAddToCart;
    }

    isHasStock(): boolean {
        return this.hasStock;
    }

    isShowAddToCartAndQuantity(): boolean {
        return this.showAddToCartAndQuantity;
    }

    ngOnInit() {
        this.itemAddedToCartLabel = 'addToCart';
        if (this.product) {
            this.updateStockInfo(this.product);
        }
        this.quantity = 0;
        this.oldQuantity = 0;
        this.subscription.add(this.activeCartService
            .getEntries()
            .subscribe(entries => {
                this.quantity = 0;
                this.oldQuantity = 0;
                let changeCounter: number = 0;
                entries
                    .filter(e => e.product.code == this.product.code)
                    .map(entry => {
                        this.quantity += entry.quantity;
                        const cartEntry: ValioCartEntry = entry as ValioCartEntry;
                        if (cartEntry.originalQuantity > 0) {// if the item was ordered previously. Cart use status, because sttaus is open if line is modified
                            this.oldQuantity += cartEntry.quantity;
                        }
                        changeCounter++;
                    });
                if (changeCounter > 0) {
                    // Fire event after entry iteration. Doesn't detect all changes if in entry iteration
                    this.cdr.detectChanges();
                }
            }));

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        super.ngOnDestroy();
    }

    updateStockInfo(product: Product): void {
        if (product.stock) {
            if (product.stock.stockLevel > 0) {
                this.maxQuantity = product.stock.stockLevel;
                this.hasStock = true;
            }
            this.showAddToCart = true;
        }
        this.showQuantity = false;
    }


    addToCart() {
        this.addToCartCallBack();
        this.cdr.detectChanges();
        this.updateAmount({qty: this.quantity} as ValioCartEntryUpdateData);
    }

    updateQuantity(value: ValioCartEntryUpdateData) {
        this.quantity = value.qty;
    }


    updateAmount(value: ValioCartEntryUpdateData): void {
        if (value.qty == 0) {
            value.qty = 1;
        }
        const firstClick = this.quantity == value.qty;
        value.qty = value.qty - this.oldQuantity;
        value.allowMerge = true;

        const whatWasAdded = value.qty;

        this.cdr.detectChanges();
        if (!firstClick || this.showAddToCartAndQuantity) {
            setTimeout(() => {
                if (whatWasAdded == value.qty && value.qty > 0) {// prevent fast clicking of the + / -
                    this.cartService.addEntryToCart(this.productCode, value);
                    this.googleAnalyticsService.cartEvent(this.product, value.qty);
                    this.itemAddedToCartLabel = 'itemAddedToCart';
                    this.cdr.detectChanges();
                }
            }, 1300);
        }
    }

    addToCartCallBack() {
        this.showAddToCart = false;
        this.showQuantity = true;
    }

    convertDate(d: string): Date {
        return d ? DateUtils.convertDate(d) : DateUtils.getNow();
    }

    addToDate(product: ValioProduct, date: string) {
        this.cartService.addEntryToCart(product.code, {qty: 1, date: date} as ValioCartEntryUpdateData);
    }
}
