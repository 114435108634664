import {
  BASE_ORDER_LOAD_SUCCESS,
  ORDER_TEMPLATE_DATA,
  ORDER_TEMPLATES_FAIL,
  ORDER_TEMPLATES_SUCCESS,
  OrderTemplatesState,
  OrderTemplateState,
  ValioBaseOrderLoadFail,
  ValioBaseOrderLoadSuccess,
  ValioOrderTemplateFail,
  ValioOrderTemplateSuccess
} from "./valio-order-template.action";
import {InjectionToken, Provider} from "@angular/core";
import {ActionReducer, ActionReducerMap, MetaReducer} from "@ngrx/store";
import {SELECT_UNIT} from "../user/valio-user-actions";
import {openCloseSpinner} from "../util/valio-modals-utils";
import {AuthActions, StateUtils} from "@spartacus/core";


export const initialState: OrderTemplateState = {
  templates: [],
  active: '',
  baseOrder: null,
  loaded: false
};

export const reducerToken: InjectionToken<ActionReducerMap<OrderTemplatesState>> = new InjectionToken<ActionReducerMap<OrderTemplatesState>>('OrderTemplateReducers');

export const valioOrderTemplateReducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getOrderTemplateReducers,
};

export const metaReducers: MetaReducer<any>[] = [clearState];

export function clearState(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    if (action.type === SELECT_UNIT ||
      action.type === AuthActions.LOGIN) {
      state = initialState;
    }
    return reducer(state, action);
  };
}

export function getOrderTemplateReducers(): ActionReducerMap<OrderTemplatesState> {
  return {
    active: StateUtils.loaderReducer<OrderTemplateState>(ORDER_TEMPLATE_DATA, valioOrderTemplateReducer),
  };
}

export function valioOrderTemplateReducer(
  state = initialState,
  action: ValioBaseOrderLoadSuccess | ValioOrderTemplateSuccess | ValioOrderTemplateFail | ValioBaseOrderLoadFail
): OrderTemplateState {
  let ret = {...state};
  switch (action.type) {
    case ORDER_TEMPLATES_SUCCESS: {
      if (action.templates) {
        ret = {
          ...ret,
          loaded: true,
          templates: action.templates,
        };
      }
      if (action.active) {
        ret = {
          ...ret,
          loaded: true,
          active: action.active,
        };
      }
      break;
    }
    case ORDER_TEMPLATES_FAIL: {
      if (action.templates) {
        ret = {
          ...ret,
          loaded: true,
          templates: [],
        };
      }
      if (action.active) {
        ret = {
          ...ret,
          loaded: true,
          active: null,
        };
      }
      break;
    }
    case BASE_ORDER_LOAD_SUCCESS: {
      ret = {
        ...ret,
        baseOrder: (action as ValioBaseOrderLoadSuccess).baseOrder
      }
      break;
    }
  }
  return ret;
}
